unit VoziloMenjava;

interface

uses
  System.SysUtils, System.Variants, System.Classes, Web,
  Vcl.Graphics, Vcl.Controls, Vcl.Forms, Vcl.Dialogs, Vcl.StdCtrls,
  WEBLib.StdCtrls, WEBLib.ExtCtrls, Data.DB, WEBLib.DB, XData.Web.JsonDataset,
  XData.Web.Dataset, WEBLib.Controls, WEBLib.Grids, WEBLib.DBCtrls, WEBLib.Forms,
  XData.Web.Connection, Vcl.Grids;

type
  TfrmMenjava = class(TForm)
    tmrFind: TTimer;
    wdsVozilo: TXDataWebDataSet;
    dsVozilo: TDataSource;
    panFooter: TPanel;
    btnSelect: TButton;
    gridView: TDBGrid;
    btnCancel: TButton;
    lblTitle: TLabel;
    procedure tmrFindTimer(Sender: TObject);
    procedure edtFilterChange(Sender: TObject);
    procedure tblStrankeDblClick(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
    procedure wdsVoziloAfterOpen(DataSet: TDataSet);
    procedure btnCancelClick(Sender: TObject);
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
  private
    { Private declarations }
//    SrcPlate: string;
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

implementation

{$R *.dfm}

uses Client.Core, Main, Client.Settings;

procedure TfrmMenjava.btnCancelClick(Sender: TObject);
begin
  frmMain.ShowVozila;
end;

procedure TfrmMenjava.edtFilterChange(Sender: TObject);
begin
  tmrFind.Enabled := false;
  tmrFind.Enabled := true;
end;

procedure TfrmMenjava.tblStrankeDblClick(Sender: TObject);
begin
  modMain.NastaviMenjavo(wdsVozilo.FieldByName('Id').AsInteger);
  frmMain.ShowFrakcija;
end;

procedure TfrmMenjava.tmrFindTimer(Sender: TObject);
begin
  tmrFind.Enabled := false;
  Log('Skipping VoziloId='+modMain.VoziloId.ToString);
  wdsVozilo.Close;
  wdsVozilo.QueryString := '$filter=Id ne '+modMain.VoziloId.ToString+'&$orderby=RegistrskaSt';
  wdsVozilo.Load;
  gridView.Columns[0].Width := gridView.Width;
end;

procedure TfrmMenjava.wdsVoziloAfterOpen(DataSet: TDataSet);
begin
  lblTitle.Caption := 'Izberi menjavo za '+frmMain.lblVozilo.Caption;
  btnSelect.Enabled := DataSet.RecordCount > 0;
end;

procedure TfrmMenjava.WebFormClose(Sender: TObject; var Action: TCloseAction);
begin
  Action := caFree;
end;

procedure TfrmMenjava.WebFormCreate(Sender: TObject);
begin
//  connServer.URL := Settings.ServerURL;
//  connServer.Connected := true;
end;

procedure TfrmMenjava.WebFormShow(Sender: TObject);
begin
  gridView.ColWidths[0] := gridView.Width - 5;
end;

procedure TfrmMenjava.LoadDFMValues;
begin
  inherited LoadDFMValues;

  lblTitle := TLabel.Create(Self);
  panFooter := TPanel.Create(Self);
  btnSelect := TButton.Create(Self);
  btnCancel := TButton.Create(Self);
  gridView := TDBGrid.Create(Self);
  tmrFind := TTimer.Create(Self);
  wdsVozilo := TXDataWebDataSet.Create(Self);
  dsVozilo := TDataSource.Create(Self);

  lblTitle.BeforeLoadDFMValues;
  panFooter.BeforeLoadDFMValues;
  btnSelect.BeforeLoadDFMValues;
  btnCancel.BeforeLoadDFMValues;
  gridView.BeforeLoadDFMValues;
  tmrFind.BeforeLoadDFMValues;
  wdsVozilo.BeforeLoadDFMValues;
  dsVozilo.BeforeLoadDFMValues;
  try
    Name := 'frmMenjava';
    Width := 768;
    Height := 923;
    Align := alClient;
    Caption := 'modMain.connServer';
    Color := 14803455;
    CSSLibrary := cssBootstrap;
    ElementFont := efCSS;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    lblTitle.SetParentComponent(Self);
    lblTitle.Name := 'lblTitle';
    lblTitle.Left := 0;
    lblTitle.Top := 0;
    lblTitle.Width := 768;
    lblTitle.Height := 13;
    lblTitle.Align := alTop;
    lblTitle.Alignment := taCenter;
    lblTitle.Caption := 'Izberi menjavo za vozilo';
    lblTitle.Color := 14803455;
    lblTitle.ElementClassName := 'display-3 text-danger';
    lblTitle.ElementFont := efCSS;
    lblTitle.HeightStyle := ssAuto;
    lblTitle.HeightPercent := 100.000000000000000000;
    lblTitle.WidthPercent := 100.000000000000000000;
    panFooter.SetParentComponent(Self);
    panFooter.Name := 'panFooter';
    panFooter.Left := 0;
    panFooter.Top := 803;
    panFooter.Width := 768;
    panFooter.Height := 120;
    panFooter.Align := alBottom;
    panFooter.BorderStyle := bsNone;
    panFooter.ChildOrder := 3;
    panFooter.Color := clWhite;
    btnSelect.SetParentComponent(panFooter);
    btnSelect.Name := 'btnSelect';
    btnSelect.AlignWithMargins := True;
    btnSelect.Left := 593;
    btnSelect.Top := 5;
    btnSelect.Width := 165;
    btnSelect.Height := 112;
    btnSelect.Margins.Top := 5;
    btnSelect.Margins.Right := 10;
    btnSelect.Align := alRight;
    btnSelect.Caption := '<i class="material-icons" style="font-size:40px;">done</i>';
    btnSelect.ChildOrder := 1;
    btnSelect.ElementClassName := 'btn btn-success';
    btnSelect.ElementFont := efCSS;
    btnSelect.Enabled := False;
    btnSelect.HeightPercent := 100.000000000000000000;
    btnSelect.WidthPercent := 100.000000000000000000;
    SetEvent(btnSelect, Self, 'OnClick', 'tblStrankeDblClick');
    btnCancel.SetParentComponent(panFooter);
    btnCancel.Name := 'btnCancel';
    btnCancel.AlignWithMargins := True;
    btnCancel.Left := 10;
    btnCancel.Top := 3;
    btnCancel.Width := 111;
    btnCancel.Height := 114;
    btnCancel.Margins.Left := 10;
    btnCancel.Margins.Right := 10;
    btnCancel.Align := alLeft;
    btnCancel.Caption := '<i class="material-icons" style="font-size:40px;">close</i>';
    btnCancel.ChildOrder := 1;
    btnCancel.ElementClassName := 'btn btn-danger';
    btnCancel.ElementFont := efCSS;
    btnCancel.HeightPercent := 100.000000000000000000;
    btnCancel.WidthPercent := 100.000000000000000000;
    SetEvent(btnCancel, Self, 'OnClick', 'btnCancelClick');
    gridView.SetParentComponent(Self);
    gridView.Name := 'gridView';
    gridView.Left := 0;
    gridView.Top := 13;
    gridView.Width := 768;
    gridView.Height := 790;
    gridView.Align := alClient;
    gridView.Color := 14019327;
    gridView.Columns.Clear;
    with gridView.Columns.Add do
    begin
      ElementClassName := 'display-1';
      DataField := 'RegistrskaSt';
      Title := 'Opis';
      Width := 640;
    end;
    gridView.DataSource := dsVozilo;
    gridView.ElementFont := efCSS;
    gridView.ElementClassName := 'fs-2';
    gridView.FixedCols := 0;
    gridView.FixedRows := 0;
    gridView.Font.Charset := DEFAULT_CHARSET;
    gridView.Font.Color := clWindowText;
    gridView.Font.Height := -85;
    gridView.Font.Name := 'Tahoma';
    gridView.Font.Style := [];
    gridView.Options := [goColSizing,goRowSelect];
    gridView.ParentFont := False;
    gridView.TabOrder := 1;
    gridView.HeightPercent := 100.000000000000000000;
    gridView.ShowIndicator := False;
    gridView.WidthPercent := 100.000000000000000000;
    SetEvent(gridView, Self, 'OnDblClick', 'tblStrankeDblClick');
    gridView.ColWidths[0] := 640;
    tmrFind.SetParentComponent(Self);
    tmrFind.Name := 'tmrFind';
    tmrFind.Interval := 500;
    SetEvent(tmrFind, Self, 'OnTimer', 'tmrFindTimer');
    tmrFind.Left := 108;
    tmrFind.Top := 104;
    wdsVozilo.SetParentComponent(Self);
    wdsVozilo.Name := 'wdsVozilo';
    wdsVozilo.AfterOpen := wdsVoziloAfterOpen;
    wdsVozilo.EntitySetName := 'Vozilo';
    wdsVozilo.Connection := modMain.connServer;
    wdsVozilo.SubpropsDepth := 1;
    wdsVozilo.Left := 104;
    wdsVozilo.Top := 256;
    dsVozilo.SetParentComponent(Self);
    dsVozilo.Name := 'dsVozilo';
    dsVozilo.DataSet := wdsVozilo;
    dsVozilo.Left := 104;
    dsVozilo.Top := 312;
  finally
    lblTitle.AfterLoadDFMValues;
    panFooter.AfterLoadDFMValues;
    btnSelect.AfterLoadDFMValues;
    btnCancel.AfterLoadDFMValues;
    gridView.AfterLoadDFMValues;
    tmrFind.AfterLoadDFMValues;
    wdsVozilo.AfterLoadDFMValues;
    dsVozilo.AfterLoadDFMValues;
  end;
end;

end.

unit Dan;

interface

uses
  System.SysUtils, System.Variants, System.Classes, Web, System.DateUtils,
  Vcl.Graphics, Vcl.Controls, Vcl.Forms, Vcl.Dialogs, Vcl.StdCtrls,
  WEBLib.StdCtrls, WEBLib.ExtCtrls, Data.DB, WEBLib.DB, XData.Web.JsonDataset,
  XData.Web.Dataset, WEBLib.Controls, WEBLib.Grids, WEBLib.DBCtrls, WEBLib.Forms,
  XData.Web.Connection, Vcl.Grids;

type
  TfrmDan = class(TWebForm)
    tmrFind: TWebTimer;
    wdsDan: TXDataWebDataSet;
    dsDan: TWebDataSource;
    panFooter: TWebPanel;
    btnSelect: TWebButton;
    gridView: TWebDBGrid;
    btnCancel: TWebButton;
    procedure tmrFindTimer(Sender: TObject);
    procedure edtFilterChange(Sender: TObject);
    procedure tblStrankeDblClick(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
    procedure wdsDanAfterOpen(DataSet: TDataSet);
    procedure btnCancelClick(Sender: TObject);
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
  private
    { Private declarations }
  public
    { Public declarations }
  end;

implementation

{$R *.dfm}

uses Client.Core, Main, Client.Settings;

procedure TfrmDan.btnCancelClick(Sender: TObject);
begin
  frmMain.ShowKoledar;
end;

procedure TfrmDan.edtFilterChange(Sender: TObject);
begin
  tmrFind.Enabled := false;
  tmrFind.Enabled := true;
end;

procedure TfrmDan.tblStrankeDblClick(Sender: TObject);
var datum: TDateTime;
    danes, izbrano, dni: integer;
begin
  danes := modMain.VrniDanVTednu(Now);
  izbrano := wdsDan.FieldByName('Id').AsInteger;

  modMain.log('Izbrano = '+Izbrano.ToString);
  modMain.log('Danes = '+Danes.ToString);

  if danes < izbrano then begin
    dni := 7-(izbrano-danes);
  end else if danes > izbrano then begin
    dni := danes-izbrano
  end else begin
    dni := 0;
  end;
  modMain.log('Dni='+Dni.ToString);
  datum :=  IncDay(Now, 0-dni);
  modMain.log('Datum='+DateTimeToStr(datum));

//  datum := IncDay(Now, wdsDan.FieldByName('Id').AsInteger);
  modMain.NastaviDatum(datum);
  frmMain.ShowVozila;
end;

procedure TfrmDan.tmrFindTimer(Sender: TObject);
//var dan: integer;
begin
  tmrFind.Enabled := false;
  wdsDan.Close;
  wdsDan.QueryString := '$orderby=Id';
  wdsDan.Load;
end;

procedure TfrmDan.wdsDanAfterOpen(DataSet: TDataSet);
var dan: integer;
begin
  dan := modMain.VrniDanVTednu(modMain.IzbranDatum); // Pozor, začne z nedeljo
  wdsDan.Locate('Id', dan, []);

  btnSelect.Enabled := DataSet.RecordCount > 0;
  gridView.Columns[0].Width := gridView.Width;
end;

procedure TfrmDan.WebFormClose(Sender: TObject; var Action: TCloseAction);
begin
  Action := caFree;
end;

procedure TfrmDan.WebFormCreate(Sender: TObject);
begin
//  connServer.URL := Settings.ServerURL;
//  connServer.Connected := true;
end;

procedure TfrmDan.WebFormShow(Sender: TObject);
begin
  gridView.ColWidths[0] := gridView.Width - 5;
end;

end.

{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2018 - 2022                               }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.JQGrid;

{$DEFINE NOPP}

interface

uses
  Classes, SysUtils, WebLib.Controls, js, libJQuery, jqwidget,
  WebLib.JQCtrls, types;

type
  TJQXGridColumnType = (gctDefault, gctEmail, gctLink, gctImage);
  TJQXGridEditor = (geEdit, geCheckBox, geDateTimeInput, geDropDownList, geNumberInput, geNone);
  TJQXGridSelection = (gsSingleCell, gsSingleRow, gsMultipleCells, gsMultipleRows);
//  TJQXGridScrollMode = (gsmPixelScrolling, gsmItemScrolling);
  TJQXGridSortDirection = (gsdAscending, gsdDescending, gsdUnsorted);
  TJQXGridDataType = (gdtArray, gdtCSV, gdtJSON, gdtNone);
  TJQXGridColumnDataType = (gcdString, gcdInteger, gcdDouble, gcdDate);

  TJQXCellEventArgs = class(TPersistent)
  private
    FARow: Integer;
    FACol: Integer;
    FADataField: string;
    FAValue: string;
    FAId: string;
  published
    property ACol: Integer read FACol write FACol;
    property ARow: Integer read FARow write FARow;
    property ADataField: string read FADataField write FADataField;
    property AId: string read FAId write FAId;
    property AValue: string read FAValue write FAValue;
  end;

  TJQXCellEvent = procedure(Sender: TObject; Event: TJQXCellEventArgs) of object;

  TJQXCellDataEventArgs = class(TPersistent)
  private
    FARow: Integer;
    FACol: Integer;
    FADataField: string;
    FAValue: string;
    FAId: string;
    FAData: string;
  published
    property ACol: Integer read FACol write FACol;
    property ARow: Integer read FARow write FARow;
    property AData: string read FAData write FAData;
    property ADataField: string read FADataField write FADataField;
    property AId: string read FAId write FAId;
    property AValue: string read FAValue write FAValue;
  end;

  TJQXCellDataEvent = procedure(Sender: TObject; Event: TJQXCellDataEventArgs) of object;

  TJQXCellEditEventArgs = class(TPersistent)
  private
    FARow: Integer;
    FACol: Integer;
    FADataField: string;
    FAValue: string;
    FAId: string;
    FAOldValue: string;
  published
    property ACol: Integer read FACol write FACol;
    property ARow: Integer read FARow write FARow;
    property ADataField: string read FADataField write FADataField;
    property AId: string read FAId write FAId;
    property AValue: string read FAValue write FAValue;
    property AOldValue: string read FAOldValue write FAOldValue;
  end;

  TJQXCellEditEvent = procedure(Sender: TObject; Event: TJQXCellEditEventArgs) of object;

  TJQXCellValidateEventArgs = class(TPersistent)
  private
    FARow: Integer;
    FACol: Integer;
    FADataField: string;
    FAValue: string;
    FAId: string;
    FAOldValue: string;
    FAMessage: string;
    FAAllow: Boolean;
  published
    property ACol: Integer read FACol write FACol;
    property ARow: Integer read FARow write FARow;
    property ADataField: string read FADataField write FADataField;
    property AId: string read FAId write FAId;
    property AValue: string read FAValue write FAValue;
    property AOldValue: string read FAOldValue write FAOldValue;
    property AAllow: Boolean read FAAllow write FAAllow default true;
    property AMessage: string read FAMessage write FAMessage;
  end;

  TJQXCellValidateEvent = procedure(Sender: TObject; Event: TJQXCellValidateEventArgs) of object;

  TJQXPageEventArgs = class(TPersistent)
  private
    FAPageIndex: Integer;
    FAPageSize: Integer;
  published
    property APageIndex: Integer read FAPageIndex write FAPageIndex;
    property APageSize: Integer read FAPageSize write FAPageSize;
  end;

  TJQXPageEvent = procedure(Sender: TObject; Event: TJQXPageEventArgs) of object;

  TJQXRowEventArgs = class(TPersistent)
  private
    FARowIndex: Integer;
  published
    property ARowIndex: Integer read FARowIndex write FARowIndex;
  end;

  TJQXRowEvent = procedure(Sender: TObject; Event: TJQXRowEventArgs) of object;

  TJQXSortEventArgs = class(TPersistent)
  private
    FACol: Integer;
    FASortDirection: TJQXGridSortDirection;
    FADataField: string;
  published
    property ACol: Integer read FACol write FACol;
    property ADataField: string read FADataField write FADataField;
    property ASortDirection: TJQXGridSortDirection read FASortDirection write FASortDirection default gsdUnsorted;
  end;

  TJQXSortEvent = procedure(Sender: TObject; Event: TJQXSortEventArgs) of object;

  TJQXFilterEventArgs = class(TPersistent)
  private
    FACol: Integer;
    FADataField: string;
    FAValue: string;
    FACondition: string;
  published
    property ACol: Integer read FACol write FACol;
    property ADataField: string read FADataField write FADataField;
    property AValue: string read FAValue write FAValue;
    property ACondition: string read FACondition write FACondition;
  end;

  TJQXFilterEvent = procedure(Sender: TObject; Event: TJQXFilterEventArgs) of object;

  TJQXGrid = class;
  TJQXGridColumns = class;
  TJQXGridOptions = class;

  TJQXGridColumn = class(TCollectionItem)
  private
    FAlignment: TAlignment;
    FDataField: string;
    FEditor: TJQXGridEditor;
    FFormat: string;
    FTitle: string;
    FWidth: Integer;
    FOwner: TJQXGridColumns;
    FFreeze: Boolean;
    FVisible: Boolean;
    FColumnType: TJQXGridColumnType;
    FDataType: TJQXGridColumnDataType;
    procedure SetAlignment(const Value: TAlignment);
    procedure SetDataField(const Value: string);
    procedure SetEditor(const Value: TJQXGridEditor);
    procedure SetFormat(const Value: string);
    procedure SetTitle(const Value: string);
    procedure SetWidth(const Value: Integer);
    procedure SetFreeze(const Value: Boolean);
    procedure SetVisible(const Value: Boolean);
    procedure SetDataType(const Value: TJQXGridColumnDataType);
  public
    constructor Create(Collection: TCollection);  override;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
    function GetOwner: TPersistent; override;
  protected
    function GetDisplayName: string; override;
  published
    property Alignment: TAlignment read FAlignment write SetAlignment default taLeftJustify;
    property ColumnType: TJQXGridColumnType read FColumnType write FColumnType default gctDefault;
    property DataField: string read FDataField write SetDataField;
    property DataType: TJQXGridColumnDataType read FDataType write SetDataType;
    property Editor: TJQXGridEditor read FEditor write SetEditor default geEdit;
    property Freeze: Boolean read FFreeze write SetFreeze default False;
    property Format: string read FFormat write SetFormat;
    property Title: string read FTitle write SetTitle;
    property Visible: Boolean read FVisible write SetVisible default True;
    property Width: Integer read FWidth write SetWidth default 100;
  end;

  TJQXGridColumns = class(TCollection)
  private
    FOwner: TJQXGrid;
    function GetItems(Index: Integer): TJQXGridColumn;
    procedure SetItems(Index: Integer; const Value: TJQXGridColumn);
  protected
    procedure Update(Item: TCollectionItem); override;
  public
    constructor Create(AOwner: TJQXGrid); overload;
    function GetOwner: TPersistent; override;
    function Add: TJQXGridColumn; reintroduce;
    function Insert(Index: Integer):TJQXGridColumn; reintroduce;
    property Items[Index: Integer]: TJQXGridColumn read GetItems write SetItems; default;
  end;

  TJQXGridBands = class(TPersistent)
  private
    FOwner: TJQXGridOptions;
    FRowCount: Integer;
    FEnabled: Boolean;
    procedure SetEnabled(const Value: Boolean);
    procedure SetRowCount(const Value: Integer);
  public
    constructor Create(AOwner: TJQXGridOptions); overload;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
    function GetOwner: TPersistent; override;

    property Enabled: Boolean read FEnabled write SetEnabled default False;
    property RowCount: Integer read FRowCount write SetRowCount default 1;
  end;

  TJQXGridPaging = class(TPersistent)
  private
    FOwner: TJQXGridOptions;
    FEnabled: Boolean;
    FPageSize: Integer;
    procedure SetEnabled(const Value: Boolean);
    procedure SetPageSize(const Value: Integer);
  public
    constructor Create(AOwner: TJQXGridOptions); overload;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
    function GetOwner: TPersistent; override;

    property Enabled: Boolean read FEnabled write SetEnabled default False;
    property PageSize: Integer read FPageSize write SetPageSize default 10;
   end;

  TJQXGridSorting = class(TPersistent)
  private
    FOwner: TJQXGridOptions;
    FEnabled: Boolean;
    FDirection: TJQXGridSortDirection;
    FColumnIndex: Integer;
    procedure SetEnabled(const Value: Boolean);
    procedure SetColumnIndex(const Value: Integer);
    procedure SetDirection(const Value: TJQXGridSortDirection);
  public
    constructor Create(AOwner: TJQXGridOptions); overload;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
    function GetOwner: TPersistent; override;

    property Enabled: Boolean read FEnabled write SetEnabled default False;
    property ColumnIndex: Integer read FColumnIndex write SetColumnIndex default -1;
    property Direction: TJQXGridSortDirection read FDirection write SetDirection default gsdAscending;
   end;

  TJQXGridOptions = class(TPersistent)
  private
    FOwner: TJQXGrid;
    FEditing: Boolean;
    FFiltering: Boolean;
    FGrouping: Boolean;
    FSelectionMode: TJQXGridSelection;
    FHovering: Boolean;
    FBands: TJQXGridBands;
    FPaging: TJQXGridPaging;
    FSorting: TJQXGridSorting;
    procedure SetEditing(const Value: Boolean);
    procedure SetFiltering(const Value: Boolean);
    procedure SetGrouping(const Value: Boolean);
    procedure SetSelectionMode(const Value: TJQXGridSelection);
    procedure SetHovering(const Value: Boolean);
  public
    constructor Create(AOwner: TJQXGrid); overload;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
    function GetOwner: TPersistent; override;
  published
    property Bands: TJQXGridBands read FBands write FBands;
    property Editing: Boolean read FEditing write SetEditing default True;
    property Filtering: Boolean read FFiltering write SetFiltering default False;
    property Grouping: Boolean read FGrouping write SetGrouping default False;
    property Hovering: Boolean read FHovering write SetHovering default True;
    property Paging: TJQXGridPaging read FPaging write FPaging;
    property Sorting: TJQXGridSorting read FSorting write FSorting;
    property SelectionMode: TJQXGridSelection read FSelectionMode
      write SetSelectionMode default gsSingleRow;
  end;

  TJQXGridData = class(TPersistent)
  private
    FOwner: TJQXGrid;
    FDataDelimiter: char;
    FDataType: TJQXGridDataType;
    FDataUrl: string;
    FDataId: string;
    FDataJSON: TJSJSON;
    FDataArray: TJSArray;
    procedure SetDataArray(const Value: TJSArray);
    procedure SetDataDelimiter(const Value: char);
    procedure SetDataId(const Value: string);
    procedure SetDataJSON(const Value: TJSJSON);
    procedure SetDataType(const Value: TJQXGridDataType);
    procedure SetDataUrl(const Value: string);
  public
    constructor Create(AOwner: TJQXGrid); overload;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
    function GetOwner: TPersistent; override;

    property JSON: TJSJSON read FDataJSON write SetDataJSON;
    property DataArray: TJSArray read FDataArray write SetDataArray;
  published
    property DataType: TJQXGridDataType read FDataType write SetDataType default gdtJSON;
    property Url: string read FDataUrl write SetDataUrl;
    property Id: string read FDataId write SetDataId;
    property Delimiter: char read FDataDelimiter write SetDataDelimiter;
  end;

  TJQXGridCellRec = record
    Col, Row: Integer;
  end;

  TJQXGridExportState = (esExportStart, esExportNewRow, esExportDone, esExportSelRow, esExportFail, esExportNextRow, esExportFindRow);

  TJQXGridAdapter = class(TComponent)
  private
    FBlockAdd: Boolean;
    FGrid: TJQXGrid;
    FActive: Boolean;
    procedure SetActive(const Value: boolean);
    procedure SetGrid(const Value: TJQXGrid);
  protected
    procedure Notification(AComponent: TComponent; Operation: TOperation); override;
    procedure Initialize; virtual;
    procedure UpdateBounds; virtual;
    procedure SelectCell(ACell: TJQXGridCellRec); virtual;
    procedure CellEditValidateData(ACol, ARow: Integer; var CellString: String; var Allow: Boolean); virtual;
    procedure CellEditGetData(ACol, ARow: Integer; var CellString: String); virtual;
    procedure CellBeforeEdit(ACol, ARow: Integer); virtual;
    procedure GetCellData(ACol, ARow: Integer; ADataField: string; var ACellData: string); virtual;
    procedure ExportNotification(AState: TJQXGridExportState; ARow: Integer); virtual;
  public
    constructor Create(AOwner: TComponent); override;
  published
    property Active: Boolean read FActive write SetActive default False;
    property Grid: TJQXGrid read FGrid write SetGrid;
  end;

  TJQXGrid = class(TJQXCustomControl)
  private
    FPrevFocusedCell: TJQXGridCellRec;
    FBlockAdd: Boolean;
    FColumns: TJQXGridColumns;
    FIsLoaded: Boolean;
    FIsEditing: Boolean;
    FIsBinding: Boolean;
    FIsRendered: Integer;
    FDoUpdateVisualsAfterBinding: Boolean;
    FEnableCellRender: Boolean;
    FOptions: TJQXGridOptions;
    FRowCount: Integer;
    FUpdateCount: Integer;
    FOnCellEditDone: TJQXCellEditEvent;
    FOnCellEditStart: TJQXCellEvent;
    FOnCellClick: TJQXCellEvent;
    FRowHeight: Integer;
    FSelectedRow: TList;
//    FScrollMode: TJQXGridScrollMode;
    FOnCellSelect: TJQXCellEvent;
    FOnPageChange: TJQXPageEvent;
    FOnRowSelect: TJQXRowEvent;
    FOnRowClick: TJQXRowEvent;
    FOnSort: TJQXSortEvent;
    FOnGetCellData: TJQXCellDataEvent;
    FOnCellEditValidate: TJQXCellValidateEvent;
    FOnFilter: TJQXFilterEvent;
    FData: TJQXGridData;
    FAdapter: TJQXGridAdapter;
    procedure SetRowCount(const Value: Integer);
    function GetCells(Col, Row: Integer): string;
    procedure SetCells(Col, Row: Integer; const Value: string);
    function HandleCellEditDone(Event: TJQXEvent): boolean;
    function HandleCellEditStart(Event: TJQXEvent): boolean;
    function HandleCellClick(Event: TJQXEvent): boolean;
    function HandleCellSelect(Event: TJQXEvent): boolean;
    function HandlePageChange(Event: TJQXEvent): boolean;
    function HandleRowClick(Event: TJQXEvent): boolean;
    function HandleRowSelect(Event: TJQXEvent): boolean;
    function HandleSort(Event: TJQXEvent): boolean;
    function HandleFilter(Event: TJQXEvent): boolean;
    function HandleBindingComplete(Event: TJQXEvent): boolean;
    function HandleGetCellData(Row: Integer; ColumnField: string; Value: string;
      DefaultHtml: string; ColumnProperties: TJSObject): string;
    function HandleCellValidate(Cell: TJSObject; Value: string): TJSObject;
    procedure SetRowHeight(const Value: Integer);
//    procedure SetScrollMode(const Value: TJQXGridScrollMode);
    function GetRowSelect(Row: integer): boolean;
    procedure SetRowSelect(Row: integer; const Value: boolean);
    function GetRowCount: Integer;
    procedure SetOptions(const Value: TJQXGridOptions);
    procedure SetAdapter(const Value: TJQXGridAdapter);
    function GetVisibleRowCount: Integer;
    function GetFocusedCell: TJQXGridCellRec;
    procedure SetFocusedCell(const Value: TJQXGridCellRec);
    function GetPrevFocusedCell: TJQXGridCellRec;
    procedure SetPrevFocusedCell(const Value: TJQXGridCellRec);
    procedure SetColumns(const Value: TJQXGridColumns);
    procedure SetData(const Value: TJQXGridData);
  protected
    function HandleRendered(Event: TJQXEvent): boolean;
    procedure ExportNotification(AState: TJQXGridExportState; ARow: Integer); virtual;
    procedure GetCellData(ACol, ARow: Integer; ADataField: string; var ACellData: string); virtual;
    procedure BindEvents; override;
    procedure InitJQuery; override;
    procedure UpdateElement; override;
    procedure UpdateSize; reintroduce;
    procedure UpdateFull;
    procedure UpdateVisuals;
    procedure UpdateVisualsBeforeData;
    procedure UpdateVisualsAfterData;
    procedure UpdateData;
    procedure UpdateSelectionMode;
    procedure ClearData;
    function ColumnsToArray(GetCellData, GetValidate: Pointer): TJSArray;
    function DataFieldsToArray: TJSArray;
    function GetColumnIndexByDataField(ADataField: string): integer;
    function GetColumnDataFieldByIndex(AIndex: Integer): string;
    function GetRowIndexByValue(ARowIndex: string): integer;
    property Adapter: TJQXGridAdapter read FAdapter write SetAdapter;
    property PrevFocusedCell: TJQXGridCellRec read GetPrevFocusedCell write SetPrevFocusedCell;
    procedure SetWidth(AValue: Integer); override;
    procedure SetHeight(AValue: Integer); override;
  public
    procedure CreateInitialize; override;
    destructor Destroy; override;
    procedure SetTheme(AValue: string); override;

    property Cells[Col, Row: Integer]: string read GetCells write SetCells;
    property RowSelect[Row: Integer]: Boolean read GetRowSelect write SetRowSelect;
    property FocusedCell: TJQXGridCellRec read GetFocusedCell write SetFocusedCell;

    procedure BeginUpdate; override;
    procedure EndUpdate; override;

    procedure DeleteRow(Row: integer);
    procedure DeleteRows(Row, Count: Integer); virtual;
    procedure InsertRow(Row: integer);
    procedure InsertRows(Row, Count: Integer); virtual;

    procedure SelectCell(ACell: TJQXGridCellRec);
    procedure SelectRow(ARow: Integer);
    property VisibleRowCount: Integer read GetVisibleRowCount;
  published
    property Columns: TJQXGridColumns read FColumns write SetColumns;
    property Data: TJQXGridData read FData write SetData;
    property Options: TJQXGridOptions read FOptions write SetOptions;
    property RowCount: Integer read GetRowCount write SetRowCount default 5;
    property RowHeight: Integer read FRowHeight write SetRowHeight default 28;
//    property ScrollMode: TJQXGridScrollMode read FScrollMode write SetScrollMode default gsmPixelScrolling;

    property OnCellEditDone: TJQXCellEditEvent read FOnCellEditDone write FOnCellEditDone;
    property OnCellEditStart: TJQXCellEvent read FOnCellEditStart write FOnCellEditStart;
    property OnCellEditValidate: TJQXCellValidateEvent read FOnCellEditValidate write FOnCellEditValidate;
    property OnCellClick: TJQXCellEvent read FOnCellClick write FOnCellClick;
    property OnCellSelect: TJQXCellEvent read FOnCellSelect write FOnCellSelect;
    property OnGetCellData: TJQXCellDataEvent read FOnGetCellData write FOnGetCellData;
    property OnPageChange: TJQXPageEvent read FOnPageChange write FOnPageChange;
    property OnRowClick: TJQXRowEvent read FOnRowClick write FOnRowClick;
    property OnRowSelect: TJQXRowEvent read FOnRowSelect write FOnRowSelect;
    property OnSort: TJQXSortEvent read FOnSort write FOnSort;
    property OnFilter: TJQXFilterEvent read FOnFilter write FOnFilter;
  end;

  TWebJQXGrid = class(TJQXGrid);

function MakeCell(ACol,ARow: integer): TJQXGridCellRec;

implementation

{ TJQXGrid }

uses
  WEBLib.Forms;

function MakeCell(ACol,ARow: integer): TJQXGridCellRec;
begin
  Result.Col := ACol;
  Result.Row := ARow;
end;

procedure TJQXGrid.CreateInitialize;
begin
  inherited;
  Height := 200;
  Width := 300;
  FColumns := TJQXGridColumns.Create(Self);
  FData := TJQXGridData.Create(Self);
  FOptions := TJQXGridOptions.Create(Self);
  FRowCount := 5;
  FRowHeight := 28;
  FSelectedRow := TList.Create;
//  FScrollMode := gsmPixelScrolling;
  FIsLoaded := False;
  FIsEditing := False;
  FIsBinding := False;
  FIsRendered := 0;
  FDoUpdateVisualsAfterBinding := False;
  FEnableCellRender := True;

  RequiredScripts.Add('jqxdata.js');
  RequiredScripts.Add('jqxbuttons.js');
  RequiredScripts.Add('jqxscrollbar.js');
  RequiredScripts.Add('jqxdatetimeinput.js');
  RequiredScripts.Add('jqxcalendar.js');
  RequiredScripts.Add('jqxmenu.js');
  RequiredScripts.Add('jqxlistbox.js');
  RequiredScripts.Add('jqxcheckbox.js');
  RequiredScripts.Add('jqxdropdownlist.js');
//  RequiredScripts.Add('jqxgrid.js');
  RequiredScripts.Add('jqxgrid.selection.js');
  RequiredScripts.Add('jqxgrid.columnsresize.js');
  RequiredScripts.Add('jqxgrid.filter.js');
  RequiredScripts.Add('jqxgrid.sort.js');
  RequiredScripts.Add('jqxgrid.pager.js');
  RequiredScripts.Add('jqxgrid.grouping.js');
  RequiredScripts.Add('jqxgrid.pager.js');
  RequiredScripts.Add('jqxgrid.edit.js');

  ScriptLoaded := not AddRequiredScripts;
end;

procedure TJQXGrid.BeginUpdate;
begin
  inc(FUpdateCount);
end;

procedure TJQXGrid.BindEvents;
begin
end;

procedure TJQXGrid.DeleteRow(Row: integer);
begin
end;

procedure TJQXGrid.DeleteRows(Row, Count: integer);
begin
end;

destructor TJQXGrid.Destroy;
begin
  FColumns.Free;
  FData.Free;
  FOptions.Free;
  FSelectedRow.Free;
  inherited;
end;

procedure TJQXGrid.EndUpdate;
begin
  if (FUpdateCount > 0) then
  begin
    dec(FUpdateCount);

    if FUpdateCount = 0 then
      UpdateElement;
  end;
end;

procedure TJQXGrid.ExportNotification(AState: TJQXGridExportState;
  ARow: Integer);
begin
  inherited;
  if Assigned(Adapter) then
    Adapter.ExportNotification(AState, ARow);
end;

procedure TJQXGrid.GetCellData(ACol, ARow: Integer; ADataField: string; var ACellData: string);
begin
  if Assigned(Adapter) then
    Adapter.GetCellData(ACol, ARow, ADataField, ACellData);
end;

function TJQXGrid.GetCells(Col, Row: Integer): string;
begin
  if Assigned(ElementHandle) then
    Result := TJQXWidgetGrid(JQuery(GetJQID)).jqxGrid('getcellvalue', Row, Columns[Col].DataField)
  else
    Result := '';
end;

function TJQXGrid.GetRowSelect(Row: integer): boolean;
var
  a: TJSArray;
  I: Integer;
  v: String;
begin
  if (Row < FSelectedRow.Count) then
    Result := Boolean(FSelectedRow.Items[Row])
  else
    Result := False;

  if Assigned(ElementHandle) then
  begin
    a := TJSArray(TJQXWidgetGrid(JQuery(GetJQID)).jqxGrid('getselectedrowindexes'));

    for I := 0 to a.Length - 1 do
    begin
      v := string(a[I]);
      if (v <> '') then
      begin
        Result := StrToInt(v) = Row;
        if Result then
          Exit;
      end;
    end;
  end;
end;

function TJQXGrid.GetVisibleRowCount: Integer;
var
  a: TJSArray;
begin
  Result := 0;
  if Assigned(ElementHandle) then
  begin
    a := TJSArray(TJQXWidgetGrid(JQuery(GetJQID)).jqxGrid('getdisplayrows'));
    Result := a.Length;
  end;
end;

function TJQXGrid.GetColumnDataFieldByIndex(AIndex: Integer): string;
begin
  Result := '';
  if (AIndex >= 0) and (AIndex <= Columns.Count - 1) then
    Result := Columns[AIndex].DataField;
end;

function TJQXGrid.GetColumnIndexByDataField(ADataField: string): integer;
var
  I: integer;
begin
  Result := -1;
  for I := 0 to Columns.Count - 1 do
  begin
    if Columns[I].DataField <> '' then
    begin
      if ADataField = Columns[I].DataField then
        Result := I;
    end
    else
    begin
      if ADataField = IntToStr(I) then
        Result := I;
    end;
  end;
end;

function TJQXGrid.GetFocusedCell: TJQXGridCellRec;
var
  s: TJSObject;
  c, r: Integer;
begin
  Result := MakeCell(-1, -1);
  if Assigned(ElementHandle) then
  begin
    case Options.SelectionMode of
      gsSingleCell, gsMultipleCells:
      begin
        s := TJSObject(TJQXWidgetGrid(JQuery(GetJQID)).jqxGrid('getselectedcell'));
        r := StrToInt(string(s.properties['rowindex']));
        c := GetColumnIndexByDataField(string(s.properties['datafield']));
      end;
      gsSingleRow, gsMultipleRows:
      begin
        s := TJSObject(TJQXWidgetGrid(JQuery(GetJQID)).jqxGrid('getselectedrowindex'));
        r := StrToInt(s.toString);
        c := PrevFocusedCell.Col;
      end;
    end;

    Result := MakeCell(c, r);
  end;
end;

function TJQXGrid.GetPrevFocusedCell: TJQXGridCellRec;
begin
  Result := FPrevFocusedCell;
end;

function TJQXGrid.GetRowIndexByValue(ARowIndex: string): integer;
begin
  Result := -1;

  if (ARowIndex <> '') and (ARowIndex <> 'undefined') then
    Result := StrToInt(ARowIndex);
end;

function TJQXGrid.GetRowCount: Integer;
var
  o: TJSObject;
  rc: string;
begin
  Result := FRowCount;

  if Assigned(ElementHandle) then
  begin
    o := TJQXWidgetGrid(JQuery(GetJQID)).jqxGrid('getdatainformation');
    if Assigned(o) then
    begin
      rc := string(o['rowscount']);
      if (rc <> '') and (rc <> 'undefined') then
        Result := StrToInt(rc);
    end;
  end;
end;

function TJQXGrid.HandleBindingComplete(Event: TJQXEvent): boolean;
begin
  FIsBinding := False;

  if FDoUpdateVisualsAfterBinding then
  begin
    FDoUpdateVisualsAfterBinding := False;
    UpdateVisualsAfterData;
  end;

  Result := True;
end;

function TJQXGrid.HandleCellClick(Event: TJQXEvent): boolean;
var
  Args: TJQXCellEventArgs;
begin
  if Assigned(OnCellClick) then
  begin
    Args := TJQXCellEventArgs.Create;
    Args.ARow := GetRowIndexByValue(string(Event.Args.Properties['rowindex']));
    Args.ACol := GetColumnIndexByDataField(string(Event.Args.Properties['datafield']));
    Args.AValue := string(Event.Args.Properties['value']);
    OnCellClick(Self, Args);
    Args.Free;
  end;

  Result := True;
end;

function TJQXGrid.HandleCellSelect(Event: TJQXEvent): boolean;
var
  Args: TJQXCellEventArgs;
begin
  Args := TJQXCellEventArgs.Create;
  Args.ARow := GetRowIndexByValue(string(Event.Args.Properties['rowindex']));
  Args.ACol := GetColumnIndexByDataField(string(Event.Args.Properties['datafield']));
  Args.AValue := string(Event.Args.Properties['value']);

  if Assigned(OnCellSelect) then
    OnCellSelect(Self, Args);

  if Assigned(Adapter) then
    Adapter.SelectCell(MakeCell(Args.ACol, Args.ARow));

  Args.Free;
  Result := True;
end;

function TJQXGrid.HandleCellValidate(Cell: TJSObject; Value: string): TJSObject;
var
  Args: TJQXCellValidateEventArgs;
  v: string;
  a: Boolean;
begin
  Result := nil;

  Args := TJQXCellValidateEventArgs.Create;
  Args.ARow := StrToInt(string(Cell.Properties['row']));
  Args.ACol := GetColumnIndexByDataField(string(Cell.Properties['datafield']));
  Args.ADataField := string(Cell.Properties['datafield']);
  Args.AId := TJQXWidgetGrid(JQuery(GetJQID)).jqxGrid('getrowid', StrToInt(string(Cell.Properties['row'])));
  Args.AValue := Value;
  Args.AOldValue := string(Cell.Properties['value']);
  Args.AAllow := true;
  Args.AMessage := '';

  if Assigned(OnCellEditValidate) then
    OnCellEditValidate(Self, Args);

  if Assigned(Adapter) then
  begin
    v := '';
    a := Args.AAllow;
    Adapter.CellEditValidateData(Args.ACol, Args.ARow, v, a);
    Args.AAllow := a;
  end;

  if Args.Amessage <> '' then
    Result := New(['result', Args.AAllow, 'message', Args.AMessage])
  else
    Result := New(['result', Args.AAllow]);

  Args.Free;
end;

function TJQXGrid.HandleGetCellData(Row: Integer; ColumnField: string; Value: string;
  DefaultHtml: string; ColumnProperties: TJSObject): string;
var
  Args: TJQXCellDataEventArgs;
  html, v: string;
  col: Integer;
begin
  Result := DefaultHtml;

  if not FEnableCellRender then
    Exit;

  col := GetColumnIndexByDataField(ColumnField);
  v := Value;

  //Databinding
  GetCellData(col, Row, ColumnField, v);

  if v <> Value then
    html := '<div class="jqx-grid-cell-left-align" style="margin-top: 6px;">'+v+'</div>'
  else
  begin
    html := DefaultHtml;
    v := Value;
  end;

  case Columns[col].ColumnType of
    gctEmail:
      html := '<div style="margin:6px;"><a href="mailto:' + v + '">' + v + '</a></div>';
    gctImage:
      html := '<div style="margin:0px;height:' + IntToStr(RowHeight) + 'px"><img src="' + v + '" style="height: 100%"></div>';
    gctLink:
      html := '<div style="margin:6px;"><a href="' + v + '" target="_blank">' + v + '</a></div>';
  end;

  Result := html;

  if Assigned(OnGetCellData) then
  begin
    Args := TJQXCellDataEventArgs.Create;
    Args.ARow := Row;
    Args.ACol := col;
    Args.ADataField := ColumnField;
    Args.AId := TJQXWidgetGrid(JQuery(GetJQID)).jqxGrid('getrowid', Args.ARow);
    Args.AValue := v;
    Args.AData := html;
    OnGetCellData(Self, Args);
    Result := Args.AData;
    Args.Free;
  end
end;

function TJQXGrid.HandleCellEditDone(Event: TJQXEvent): boolean;
var
  Args: TJQXCellEditEventArgs;
  v: String;
  a: Boolean;
begin
  FIsEditing := False;

  Args := TJQXCellEditEventArgs.Create;
  Args.ARow := GetRowIndexByValue(string(Event.Args.Properties['rowindex']));
  Args.ACol := GetColumnIndexByDataField(string(Event.Args.Properties['datafield']));
  Args.AValue := string(Event.Args.Properties['value']);
  Args.AOldValue := string(Event.Args.Properties['oldvalue']);
  Args.ADataField := string(Event.Args.Properties['datafield']);
  Args.AId := TJQXWidgetGrid(JQuery(GetJQID)).jqxGrid('getrowid', Args.ARow);

  if Assigned(OnCellEditDone) then
    OnCellEditDone(Self, Args);

  if Assigned(Adapter) then
  begin
    v := Args.AValue;
    a := True;
    Adapter.CellEditValidateData(Args.ACol, Args.ARow, v, a);
  end;
  Args.Free;

  Result := True;
end;

function TJQXGrid.HandleCellEditStart(Event: TJQXEvent): boolean;
var
  Args: TJQXCellEventArgs;
begin
  FIsEditing := True;

  Args := TJQXCellEventArgs.Create;
  Args.ARow := GetRowIndexByValue(string(Event.Args.Properties['rowindex']));
  Args.ACol := GetColumnIndexByDataField(string(Event.Args.Properties['datafield']));
  Args.AValue := string(Event.Args.Properties['value']);
  Args.ADataField := string(Event.Args.Properties['datafield']);
  Args.AId := TJQXWidgetGrid(JQuery(GetJQID)).jqxGrid('getrowid', Args.ARow);

  if Assigned(OnCellEditStart) then
    OnCellEditStart(Self, Args);

  if Assigned(Adapter) then
    Adapter.CellBeforeEdit(Args.ACol, Args.ARow);

  Args.Free;

  Result := True;
end;

function TJQXGrid.HandlePageChange(Event: TJQXEvent): boolean;
var
  Args: TJQXPageEventArgs;
begin
  if Assigned(OnPageChange) then
  begin
    Args := TJQXPageEventArgs.Create;
    Args.APageIndex := StrToInt(string(Event.Args.Properties['pagenum']));
    Args.APageSize := StrToInt(string(Event.Args.Properties['pagesize']));
    OnPageChange(Self, Args);
    Args.Free;
  end;

  Result := True;
end;

function TJQXGrid.HandleRowSelect(Event: TJQXEvent): boolean;
var
  Args: TJQXRowEventArgs;
begin
  Args := TJQXRowEventArgs.Create;
  Args.ARowIndex := GetRowIndexByValue(string(Event.Args.Properties['rowindex']));
  if Assigned(OnRowSelect) then
    OnRowSelect(Self, Args);

  if Assigned(Adapter) then
    Adapter.SelectCell(MakeCell(FocusedCell.Col, Args.ARowIndex));

  Args.Free;

  Result := True;
end;

function TJQXGrid.HandleSort(Event: TJQXEvent): boolean;
var
  Args: TJQXSortEventArgs;
  AValue: string;
  IsAsc, IsDesc: Boolean;
  v, vAsc, vDesc: JSValue;
begin
  if Assigned(OnSort) then
  begin
    Args := TJQXSortEventArgs.Create;
    Args.ADataField := '';
    Args.ACol := -1;
    Args.ASortDirection := gsdUnsorted;

    v := TJSObject(Event.Args.Properties['sortinformation']).Properties['sortcolumn'];

    if Assigned(v) then
    begin
      AValue := string(v);

      if AValue <> 'null' then
      begin
        Args.ADataField := AValue;
        Args.ACol := GetColumnIndexByDataField(AValue);
      end;
    end;

    vAsc := TJSObject(TJSObject(Event.Args.Properties['sortinformation']).Properties['sortdirection']).Properties['ascending'];
    vDesc := TJSObject(TJSObject(Event.Args.Properties['sortinformation']).Properties['sortdirection']).Properties['descending'];

    if Assigned(vAsc) or Assigned(vDesc) then
    begin
      if Assigned(vAsc) then
      begin
        IsAsc := StrToBool(string(vAsc));
        if IsAsc then
          Args.ASortDirection := gsdAscending
      end;

      if Assigned(vDesc) then
      begin
        IsDesc := StrToBool(string(vDesc));
        if IsDesc then
          Args.ASortDirection := gsdDescending
      end;
    end;

    OnSort(Self, Args);
    Args.Free;
  end;

  Result := True;
end;

function TJQXGrid.HandleFilter(Event: TJQXEvent): boolean;
var
  Args: TJQXFilterEventArgs;
  AValue: string;
  v: JSValue;
  a: TJSArray;
begin
  if Assigned(OnFilter) then
  begin

    a := TJSArray(TJQXWidgetGrid(JQuery(GetJQID)).jqxGrid('getfilterinformation'));
    Args := TJQXFilterEventArgs.Create;
    Args.ADataField := '';
    Args.ACol := -1;
    v := TJSObject(a[a.Length - 1]).Properties['datafield'];

    if Assigned(v) then
    begin
      AValue := string(v);

      if AValue <> 'null' then
      begin
        Args.ADataField := AValue;
        Args.ACol := GetColumnIndexByDataField(AValue);
      end;
    end;

    OnFilter(Self, Args);
    Args.Free;
  end;

  Result := True;
end;

function TJQXGrid.HandleRendered(Event: TJQXEvent): boolean;
begin
  FIsRendered := FIsRendered + 1;
  if FIsRendered = 1 then
    UpdateFull;
  Result := True;
end;

function TJQXGrid.HandleRowClick(Event: TJQXEvent): boolean;
var
  Args: TJQXRowEventArgs;
begin
  if Assigned(OnRowClick) then
  begin
    Args := TJQXRowEventArgs.Create;
    Args.ARowIndex := StrToInt(string(Event.Args.Properties['rowindex']));
    OnRowClick(Self, Args);
    Args.Free;
  end;

  Result := True;
end;

procedure TJQXGrid.SelectCell(ACell: TJQXGridCellRec);
begin
  if Assigned(ElementHandle) then
  begin
    FEnableCellRender := False;

    FPrevFocusedCell := FocusedCell;

    case Options.SelectionMode of
      gsSingleCell, gsMultipleCells: TJQXWidgetGrid(JQuery(GetJQID)).jqxGrid('selectcell', ACell.Row, GetColumnDataFieldByIndex(ACell.Col));
      gsSingleRow, gsMultipleRows: TJQXWidgetGrid(JQuery(GetJQID)).jqxGrid('selectrow', ACell.Row);
    end;

    if Assigned(Adapter) then
      Adapter.SelectCell(ACell);

    FEnableCellRender := True;
  end;
end;

procedure TJQXGrid.SelectRow(ARow: Integer);
begin
  SelectCell(MakeCell(FocusedCell.Col, ARow));
end;

procedure TJQXGrid.SetAdapter(const Value: TJQXGridAdapter);
begin
  if FAdapter <> Value then
  begin
    FAdapter := Value;
    if FBlockAdd then
      Exit;

    FBlockAdd := True;
    if Assigned(FAdapter) then
    begin
      FAdapter.Grid := Self;
      FAdapter.Initialize;
    end;
    FBlockAdd := False;
  end;
end;

procedure TJQXGrid.SetCells(Col, Row: Integer; const Value: string);
var
  DataField: string;
begin
  if Assigned(ElementHandle) then
  begin
    if (Col < Columns.Count) and (Row < RowCount) then
    begin
      FEnableCellRender := False;
      Inc(FUpdateCount);
      DataField := Columns[Col].DataField;
      if DataField = '' then
        DataField := IntToStr(Col);
      Dec(FUpdateCount);
      FEnableCellRender := True;
      TJQXWidgetGrid(JQuery(GetJQID)).jqxGrid('setcellvalue', Row, DataField, Value);
    end;
  end;
end;

procedure TJQXGrid.SetColumns(const Value: TJQXGridColumns);
begin
  FColumns.Assign(Value);
end;

procedure TJQXGrid.SetData(const Value: TJQXGridData);
begin
  FData.Assign(Value);
end;

procedure TJQXGrid.SetFocusedCell(const Value: TJQXGridCellRec);
begin
  case Options.SelectionMode of
    gsSingleCell, gsMultipleCells: SelectCell(Value);
    gsSingleRow, gsMultipleRows: SelectRow(Value.Row);
  end;
end;

procedure TJQXGrid.SetOptions(const Value: TJQXGridOptions);
begin
  FOptions.Assign(Value);
end;

procedure TJQXGrid.SetPrevFocusedCell(const Value: TJQXGridCellRec);
begin
  FPrevFocusedCell := Value;
end;

{$HINTS OFF}
procedure TJQXGrid.InitJQuery;
var
  id: string;
begin
  inherited;

  if FIsLoaded then
    Exit;

  if not ScriptLoaded then
    Exit;

  if Assigned(ElementHandle) then
  begin
    FIsLoaded := True;
    TJQXWidgetGrid(JQuery(GetJQID)).jqxGrid(TJSJSON.parseObject('{"width": ' + IntToStr(Width) + ', "height": ' + IntToStr(Height) + '}'));
    TJQXWidgetGrid(JQuery(GetJQID)).on('cellbeginedit', @HandleCellEditStart);
    TJQXWidgetGrid(JQuery(GetJQID)).on('cellendedit', @HandleCellEditDone);
    TJQXWidgetGrid(JQuery(GetJQID)).on('cellclick', @HandleCellClick);
    TJQXWidgetGrid(JQuery(GetJQID)).on('cellselect', @HandleCellSelect);
    TJQXWidgetGrid(JQuery(GetJQID)).on('pagechanged', @HandlePageChange);
    TJQXWidgetGrid(JQuery(GetJQID)).on('rowselect', @HandleRowSelect);
    TJQXWidgetGrid(JQuery(GetJQID)).on('rowclick', @HandleRowClick);
    TJQXWidgetGrid(JQuery(GetJQID)).on('sort', @HandleSort);
    TJQXWidgetGrid(JQuery(GetJQID)).on('filter', @HandleFilter);
    TJQXWidgetGrid(JQuery(GetJQID)).on('bindingcomplete', @HandleBindingComplete);

    //Wait until the ready callback is triggered to init the grid's data and visuals
    //required when the grid is on a tab of a tabcontrol which is not visible on page load
    id := GetJQID;
    asm
      var cl = this;
      $(id).jqxGrid({ rendered: function(){ cl.HandleRendered();}});
    end;

    UpdateElement;
  end;
end;
{$HINTS ON}

procedure TJQXGrid.InsertRow(Row: integer);
begin

end;

procedure TJQXGrid.InsertRows(Row, Count: integer);
begin

end;

procedure TJQXGrid.SetRowCount(const Value: Integer);
begin
  FRowCount := Value;
  UpdateData;
end;

procedure TJQXGrid.SetRowHeight(const Value: Integer);
begin
  FRowHeight := Value;
  UpdateVisuals;

  if Value = -100 then
    HandleRendered(nil);
end;

procedure TJQXGrid.SetRowSelect(Row: integer; const Value: boolean);
begin
  while (Row >= FSelectedRow.Count) do
    FSelectedRow.Add(false);

  if (FSelectedRow.Items[Row] = True) and (Value) then
    Exit;

  FSelectedRow.Items[Row] := Value;

  if FIsLoaded then
    Exit;

  if Assigned(ElementHandle) then
  begin
    UpdateSelectionMode;
    if Value then
      TJQXWidgetGrid(JQuery(GetJQID)).jqxGrid('selectrow', Row)
    else
      TJQXWidgetGrid(JQuery(GetJQID)).jqxGrid('unselectrow', Row);
  end;
end;

procedure TJQXGrid.SetTheme(AValue: string);
var
  sTheme: string;
begin
  inherited;

  sTheme := 'base';
  if AValue <> '' then
    sTheme := AValue;

  if Assigned(ElementHandle) then
    TJQXWidgetGrid(JQuery(GetJQID)).jqxGrid('theme', sTheme)
end;

procedure TJQXGrid.SetHeight(AValue: Integer);
begin
  inherited;
  UpdateSize;
end;

procedure TJQXGrid.SetWidth(AValue: Integer);
begin
  inherited;
  UpdateSize;
end;

procedure TJQXGrid.UpdateSelectionMode;
var
  selmode: string;
begin
  if Assigned(ElementHandle) then
  begin
    case Options.SelectionMode of
      gsSingleCell: selmode := 'singlecell';
      gsMultipleCells: selmode := 'multiplecells';
      gsSingleRow: selmode := 'singlerow';
      gsMultipleRows: selmode := 'multiplerows';
    end;
    TJQXWidgetGrid(JQuery(GetJQID)).jqxGrid('selectionmode', selmode);
  end;
end;

//procedure TJQXGrid.SetScrollMode(const Value: TJQXGridScrollMode);
//begin
//  FScrollMode := Value;
//  UpdateVisuals;
//end;

procedure TJQXGrid.UpdateElement;
begin
  inherited;
  UpdateFull;
end;

procedure TJQXGrid.UpdateFull;
begin
  ClearData;
  UpdateVisualsBeforeData;
  UpdateData;
  UpdateVisualsAfterData;
end;

procedure TJQXGrid.UpdateData;
var
  a: TJQDataAdapter;
begin
  if IsUpdating then
    Exit;

  if FUpdateCount > 0 then
    Exit;

  if FIsEditing then
    Exit;

  if FIsRendered = 0 then
    Exit;

  if Assigned(ElementHandle) then
  begin
    if FIsLoaded then
    begin
      FIsBinding := True;
      case Data.DataType of
        gdtNone:
          a := TJQDataAdapter.New(New(['unboundmode', True, 'totalrecords', FRowCount, 'datafields', DataFieldsToArray]));

        gdtJSON:
        begin
          if Assigned(Data.JSON) then
            a := TJQDataAdapter.New(New(['datatype', 'json', 'datafields', DataFieldsToArray, 'id', Data.Id, 'localdata', Data.JSON]))
          else
            a := TJQDataAdapter.New(New(['datatype', 'json', 'datafields', DataFieldsToArray, 'id', Data.Id, 'url', Data.Url]));
        end;

        gdtArray:
          a := TJQDataAdapter.New(New(['datatype', 'array', 'datafields', DataFieldsToArray, 'id', Data.Id, 'localdata', Data.DataArray]));

        gdtCSV:
          a := TJQDataAdapter.New(New(['datatype', 'csv', 'url', Data.Url, 'datafields', DataFieldsToArray, 'columnDelimiter', Data.Delimiter]));
      end;

      TJQXWidgetGrid(JQuery(GetJQID)).jqxGrid('source', a);
    end;
  end;
end;

procedure TJQXGrid.UpdateVisuals;
begin
  UpdateVisualsBeforeData;
  UpdateVisualsAfterData
end;

{$HINTS OFF}
procedure TJQXGrid.UpdateVisualsAfterData;
var
  I: Integer;
  sdir: string;
  jqid: string;
begin
  if IsUpdating then
    Exit;

  if FUpdateCount > 0 then
    Exit;

  if FIsEditing then
    Exit;

  if FIsRendered = 0 then
    Exit;

  if Assigned(ElementHandle) then
  begin
    if FIsLoaded then
    begin
      if FIsBinding then
      begin
        FDoUpdateVisualsAfterBinding := True;
      end
      else
      begin
        TJQXWidgetGrid(JQuery(GetJQID)).jqxGrid('beginupdate');

        TJQXWidgetGrid(JQuery(GetJQID)).jqxGrid('editable', Options.Editing);
        TJQXWidgetGrid(JQuery(GetJQID)).jqxGrid('filterable', Options.Filtering);
        TJQXWidgetGrid(JQuery(GetJQID)).jqxGrid('groupable', Options.Grouping);
        TJQXWidgetGrid(JQuery(GetJQID)).jqxGrid('enablehover', Options.Hovering);
        TJQXWidgetGrid(JQuery(GetJQID)).jqxGrid('altrows', Options.Bands.Enabled);
        TJQXWidgetGrid(JQuery(GetJQID)).jqxGrid('altstep', Options.Bands.RowCount);

        for I := 0 to Columns.Count - 1 do
        begin
          if Columns[I].Freeze then
            TJQXWidgetGrid(JQuery(GetJQID)).jqxGrid('pincolumn', I)
          else
            TJQXWidgetGrid(JQuery(GetJQID)).jqxGrid('unpincolumn', I);
        end;

        TJQXWidgetGrid(JQuery(GetJQID)).jqxGrid('pageable', Options.Paging.Enabled);
        jqid := GetID;

        if not Options.Paging.Enabled then
        begin
          asm
           $('#pager' + jqid).hide();
          end;
        end
        else
        begin
          asm
           $('#pager' + jqid).show();
          end;
        end;

        if Options.Paging.Enabled then
        begin
          TJQXWidgetGrid(JQuery(GetJQID)).jqxGrid('pagesize', Options.Paging.PageSize);
          TJQXWidgetGrid(JQuery(GetJQID)).jqxGrid('pagermode', 'simple');
        end;

        TJQXWidgetGrid(JQuery(GetJQID)).jqxGrid('sortable', Options.Sorting.Enabled);
        if Options.Sorting.Enabled then
        begin
          if (Options.Sorting.ColumnIndex >= 0) and (Options.Sorting.Direction <> gsdUnsorted) then
          begin
            if Options.Sorting.Direction = gsdAscending then
              sdir := 'asc'
            else
              sdir := 'desc';
            TJQXWidgetGrid(JQuery(GetJQID)).jqxGrid('sortby', Options.Sorting.ColumnIndex, sdir);
          end;
        end;

        TJQXWidgetGrid(JQuery(GetJQID)).jqxGrid('rowsheight', RowHeight);

        UpdateSelectionMode;

  //      scrmode := 'default';
  //      if ScrollMode = gsmItemScrolling then
  //        scrmode := 'logical';
  //      TJQXWidgetGrid(JQuery(GetJQID)).jqxGrid('scrollmode', scrmode);

        TJQXWidgetGrid(JQuery(GetJQID)).jqxGrid('endupdate');
      end;
    end;
  end;
end;
{$HINTS ON}

procedure TJQXGrid.UpdateVisualsBeforeData;
begin
  if IsUpdating then
    Exit;

  if FUpdateCount > 0 then
    Exit;

  if FIsEditing then
    Exit;

  if FIsRendered = 0 then
    Exit;

  if Assigned(ElementHandle) then
  begin
    if FIsLoaded and not FIsBinding then
    begin
      TJQXWidgetGrid(JQuery(GetJQID)).jqxGrid('beginupdate');
      TJQXWidgetGrid(JQuery(GetJQID)).jqxGrid(New(['width', Width, 'height', Height]));
      TJQXWidgetGrid(JQuery(GetJQID)).jqxGrid(New(['columns', ColumnsToArray(@HandleGetCellData, @HandleCellValidate)]));
      TJQXWidgetGrid(JQuery(GetJQID)).jqxGrid('theme', Theme);
      TJQXWidgetGrid(JQuery(GetJQID)).jqxGrid('endupdate');
    end;
  end;
end;

procedure TJQXGrid.UpdateSize;
begin
  if IsUpdating then
    Exit;

  if FUpdateCount > 0 then
    Exit;

  if FIsEditing then
    Exit;

  if Assigned(ElementHandle) then
  begin
    if FIsLoaded and not FIsBinding then
      TJQXWidgetGrid(JQuery(GetJQID)).jqxGrid(New(['width', Width, 'height', Height]));
  end;
end;

procedure TJQXGrid.ClearData;
begin
  if IsUpdating then
    Exit;

  if FUpdateCount > 0 then
    Exit;

  if FIsEditing then
    Exit;

  if Assigned(ElementHandle) then
  begin
    if FIsLoaded then
    begin
      if not FIsBinding then
       TJQXWidgetGrid(JQuery(GetJQID)).jqxGrid('source', nil);
    end;
  end;
end;

function TJQXGrid.ColumnsToArray(GetCellData, GetValidate: Pointer): TJSArray;
var
  I: integer;
  a: TJSArray;
  text, cellsalign, columntype, datafield, width, hidden: string;
  editable: boolean;
begin
  Result := TJSArray.New;

  for I := 0 to Columns.Count - 1 do
  begin
    a := TJSArray.New;

    a.Push('text');
    text := Columns[I].Title;
    a.Push(text);

    columntype := '';
    editable := False;
    case Columns[I].Editor of
      geEdit: columntype := 'textbox';
      geCheckBox: columntype := 'checkbox';
      geDateTimeInput: columntype := 'datetimeinput';
      geDropDownList: columntype := 'dropdownlist';
      geNumberInput: columntype := 'numberinput';
    end;

    if Columns[I].Editor <> geNone then
      editable := True;

    a.Push('editable');
    a.Push(editable);

    a.Push('columntype');
    a.Push(columntype);

    if Columns[I].Alignment = taRightJustify then
      cellsalign := 'right'
    else if Columns[I].Alignment = taCenter then
      cellsalign := 'center'
    else
      cellsalign := 'left';

    a.Push('cellsalign');
    a.Push(cellsalign);

    if Columns[I].Format <> '' then
    begin
      a.Push('cellsformat');
      a.Push(Columns[I].Format);
    end;

    if Columns[I].DataField <> '' then
      datafield := Columns[I].DataField
    else
      datafield := IntToStr(I);

    a.Push('datafield');
    a.Push(datafield);

    if Columns[I].Width > 0 then
    begin
      width := IntToStr(Columns[I].Width);
      a.Push('width');
      a.Push(width);
    end;

    if not Columns[I].Visible then
    begin
      hidden := 'true';
      a.Push('hidden');
      a.Push(hidden);
    end;

    if Columns[I].Editor <> geCheckbox then
//    if Assigned(OnGetCellData) then
    begin
      a.Push('cellsrenderer');
      a.Push(GetCellData);
    end;

    if Assigned(OnCellEditValidate) then
    begin
      a.Push('validation');
      a.Push(GetValidate);
    end;

    Result.Push(New(TJSValueDynArray(a)));
  end;
end;

function TJQXGrid.DataFieldsToArray: TJSArray;
var
  I: integer;
  datafield, datatype: string;
begin
  Result := TJSArray.New;

  for I := 0 to Columns.Count - 1 do
  begin
    if Columns[I].DataField <> '' then
      datafield := Columns[I].DataField
    else
      datafield := IntToStr(I);

    case Columns[I].DataType of
      gcdString: datatype := 'string';
      gcdInteger: datatype := 'int';
      gcdDouble: datatype := 'double';
      gcdDate: datatype := 'date';
    end;

    Result.Push(New(['name', datafield, 'type', datatype]));
  end;
end;

{ TJQXGridColumn }

procedure TJQXGridColumn.Assign(Source: TPersistent);
begin
  if (Source is TJQXGridColumn) then
  begin
    FAlignment := (Source as TJQXGridColumn).Alignment;
    FColumnType := (Source as TJQXGridColumn).ColumnType;
    FDataField := (Source as TJQXGridColumn).DataField;
    FDataType := (Source as TJQXGridColumn).DataType;
    FEditor := (Source as TJQXGridColumn).Editor;
    FFreeze := (Source as TJQXGridColumn).Freeze;
    FFormat := (Source as TJQXGridColumn).Format;
    FTitle := (Source as TJQXGridColumn).Title;
    FWidth := (Source as TJQXGridColumn).Width;
  end;
end;

constructor TJQXGridColumn.Create(Collection: TCollection);
begin
  inherited;
  FAlignment := taLeftJustify;
  FColumnType := gctDefault;
  FDataField := '';
  FDataType := gcdString;
  FEditor := geEdit;
  FFreeze := false;
  FFormat := '';
  FTitle := '';
  FVisible := True;
  FWidth := 100;

  if Collection is TJQXGridColumns then
  begin
    FOwner := Collection as TJQXGridColumns;
  end;
end;

destructor TJQXGridColumn.Destroy;
begin
  inherited;
end;

function TJQXGridColumn.GetDisplayName: string;
begin
  Result := 'Column '+ IntToStr(Index);
end;

function TJQXGridColumn.GetOwner: TPersistent;
begin
  Result := FOwner;
end;

procedure TJQXGridColumn.SetAlignment(const Value: TAlignment);
begin
  FAlignment := Value;
  FOwner.FOwner.UpdateVisuals;
end;

procedure TJQXGridColumn.SetDataField(const Value: string);
begin
  FDataField := Value;
  FOwner.FOwner.UpdateElement;
end;

procedure TJQXGridColumn.SetDataType(const Value: TJQXGridColumnDataType);
begin
  FDataType := Value;
  FOwner.FOwner.UpdateVisuals;
end;

procedure TJQXGridColumn.SetEditor(const Value: TJQXGridEditor);
begin
  FEditor := Value;
  FOwner.FOwner.UpdateVisuals;
end;

procedure TJQXGridColumn.SetFormat(const Value: string);
begin
  FFormat := Value;
  FOwner.FOwner.UpdateVisuals;
end;

procedure TJQXGridColumn.SetFreeze(const Value: Boolean);
begin
  FFreeze := Value;
  FOwner.FOwner.UpdateVisuals;
end;

procedure TJQXGridColumn.SetTitle(const Value: string);
begin
  FTitle := Value;
  FOwner.FOwner.UpdateVisuals;
end;

procedure TJQXGridColumn.SetVisible(const Value: Boolean);
begin
  FVisible := Value;
  FOwner.FOwner.UpdateVisuals;
end;

procedure TJQXGridColumn.SetWidth(const Value: Integer);
begin
  FWidth := Value;
  FOwner.FOwner.UpdateVisuals;
end;


{ TJQXGridColumns }

function TJQXGridColumns.Add: TJQXGridColumn;
begin
  Result := TJQXGridColumn(inherited Add);
end;

constructor TJQXGridColumns.Create(AOwner: TJQXGrid);
begin
  inherited Create(TJQXGridColumn);
  FOwner := AOwner;
end;

function TJQXGridColumns.GetItems(Index: Integer): TJQXGridColumn;
begin
  Result := TJQXGridColumn(inherited Items[Index]);
end;

function TJQXGridColumns.GetOwner: TPersistent;
begin
  Result := FOwner;
end;

function TJQXGridColumns.Insert(Index: Integer): TJQXGridColumn;
begin
  Result := TJQXGridColumn(inherited Insert(Index));
end;

procedure TJQXGridColumns.SetItems(Index: Integer; const Value: TJQXGridColumn);
begin
  inherited Items[Index] := Value;
end;

procedure TJQXGridColumns.Update(Item: TCollectionItem);
begin
  inherited;
end;

{ TJQXGridBands }

procedure TJQXGridBands.Assign(Source: TPersistent);
begin
  if (Source is TJQXGridBands) then
  begin
    FEnabled := (Source as TJQXGridBands).Enabled;
    FRowCount := (Source as TJQXGridBands).RowCount;
  end;
end;

constructor TJQXGridBands.Create(AOwner: TJQXGridOptions);
begin
  FEnabled := False;
  FRowCount := 1;
  FOwner := AOwner;
end;

destructor TJQXGridBands.Destroy;
begin
  inherited;
end;

function TJQXGridBands.GetOwner: TPersistent;
begin
  Result := FOwner;
end;

procedure TJQXGridBands.SetEnabled(const Value: Boolean);
begin
  FEnabled := Value;
  FOwner.FOwner.UpdateVisuals;
end;

procedure TJQXGridBands.SetRowCount(const Value: Integer);
begin
  FRowCount := Value;
  FOwner.FOwner.UpdateVisuals;
end;

{ TJQXGridPaging }

procedure TJQXGridPaging.Assign(Source: TPersistent);
begin
  if (Source is TJQXGridPaging) then
  begin
    FEnabled := (Source as TJQXGridPaging).Enabled;
    FPageSize := (Source as TJQXGridPaging).PageSize;
  end;
end;

constructor TJQXGridPaging.Create(AOwner: TJQXGridOptions);
begin
  FEnabled := False;
  FPageSize := 10;
  FOwner := AOwner;
end;

destructor TJQXGridPaging.Destroy;
begin
  inherited;
end;

function TJQXGridPaging.GetOwner: TPersistent;
begin
  Result := FOwner;
end;

procedure TJQXGridPaging.SetEnabled(const Value: Boolean);
begin
  FEnabled := Value;
  FOwner.FOwner.UpdateFull;
end;

procedure TJQXGridPaging.SetPageSize(const Value: Integer);
begin
  FPageSize := Value;
  FOwner.FOwner.UpdateVisuals;
end;

{ TJQXGridSorting }

procedure TJQXGridSorting.Assign(Source: TPersistent);
begin
  inherited;
  if (Source is TJQXGridSorting) then
  begin
    FEnabled := (Source as TJQXGridSorting).Enabled;
    FColumnIndex := (Source as TJQXGridSorting).ColumnIndex;
    FDirection := (Source as TJQXGridSorting).Direction;
  end;
end;

constructor TJQXGridSorting.Create(AOwner: TJQXGridOptions);
begin
  FEnabled := False;
  FColumnIndex := -1;
  FDirection := gsdAscending;
  FOwner := AOwner;
end;

destructor TJQXGridSorting.Destroy;
begin
  inherited;
end;

function TJQXGridSorting.GetOwner: TPersistent;
begin
  Result := FOwner;
end;

procedure TJQXGridSorting.SetColumnIndex(const Value: Integer);
begin
  FColumnIndex := Value;
  FOwner.FOwner.UpdateVisuals;
end;

procedure TJQXGridSorting.SetDirection(const Value: TJQXGridSortDirection);
begin
  FDirection := Value;
  FOwner.FOwner.UpdateVisuals;
end;

procedure TJQXGridSorting.SetEnabled(const Value: Boolean);
begin
  FEnabled := Value;
  FOwner.FOwner.UpdateFull;
end;

{ TJQXGridData }

procedure TJQXGridData.Assign(Source: TPersistent);
begin
  if (Source is TJQXGridData) then
  begin
    FDataType := (Source as TJQXGridData).DataType;
    FDataUrl := (Source as TJQXGridData).Url;
    FDataJSON := (Source as TJQXGridData).JSON;
    FDataArray := (Source as TJQXGridData).DataArray;
    FDataId := (Source as TJQXGridData).Id;
    FDataDelimiter := (Source as TJQXGridData).Delimiter;
  end;
end;

constructor TJQXGridData.Create(AOwner: TJQXGrid);
begin
  FDataType := gdtNone;
  FDataUrl := '';
  FDataJSON := nil;
  FDataArray := nil;
  FDataId := '';
  FDataDelimiter := ',';
  FOwner := AOwner;
end;

destructor TJQXGridData.Destroy;
begin
  inherited;
end;

function TJQXGridData.GetOwner: TPersistent;
begin
  Result := FOwner;
end;

procedure TJQXGridData.SetDataArray(const Value: TJSArray);
begin
  FDataArray := Value;
  FOwner.UpdateData;
end;

procedure TJQXGridData.SetDataDelimiter(const Value: char);
begin
  FDataDelimiter := Value;
  FOwner.UpdateData;
end;

procedure TJQXGridData.SetDataId(const Value: string);
begin
  FDataId := Value;
  FOwner.UpdateData;
end;

procedure TJQXGridData.SetDataJSON(const Value: TJSJSON);
begin
  FDataJSON := Value;
  FOwner.UpdateData;
end;

procedure TJQXGridData.SetDataType(const Value: TJQXGridDataType);
begin
  FDataType := Value;
  FOwner.UpdateData;
end;

procedure TJQXGridData.SetDataUrl(const Value: string);
begin
  FDataUrl := Value;
  FOwner.UpdateData;
end;

{ TJQXGridOptions }

procedure TJQXGridOptions.Assign(Source: TPersistent);
begin
  if (Source is TJQXGridOptions) then
  begin
    FBands := (Source as TJQXGridOptions).Bands;
    FEditing := (Source as TJQXGridOptions).Editing;
    FFiltering := (Source as TJQXGridOptions).Filtering;
    FGrouping := (Source as TJQXGridOptions).Grouping;
    FHovering := (Source as TJQXGridOptions).Hovering;
    FPaging := (Source as TJQXGridOptions).Paging;
    FSelectionMode := (Source as TJQXGridOptions).SelectionMode;
    FSorting := (Source as TJQXGridOptions).Sorting;
  end;
end;

constructor TJQXGridOptions.Create(AOwner: TJQXGrid);
begin
  inherited Create;
  FBands := TJQXGridBands.Create(Self);
  FEditing := True;
  FFiltering := False;
  FGrouping := False;
  FHovering := True;
  FPaging := TJQXGridPaging.Create(Self);
  FSelectionMode := gsSingleRow;
  FSorting := TJQXGridSorting.Create(Self);
  FOwner := AOwner;
end;

destructor TJQXGridOptions.Destroy;
begin
  FBands.Free;
  FPaging.Free;
  FSorting.Free;
  inherited;
end;

function TJQXGridOptions.GetOwner: TPersistent;
begin
  Result := FOwner;
end;

procedure TJQXGridOptions.SetEditing(const Value: Boolean);
begin
  FEditing := Value;
  FOwner.UpdateVisuals;
end;

procedure TJQXGridOptions.SetFiltering(const Value: Boolean);
begin
  FFiltering := Value;
  FOwner.UpdateVisuals;
end;

procedure TJQXGridOptions.SetGrouping(const Value: Boolean);
begin
  FGrouping := Value;
  FOwner.UpdateFull;
end;

procedure TJQXGridOptions.SetHovering(const Value: Boolean);
begin
  FHovering := Value;
  FOwner.UpdateVisuals;
end;

procedure TJQXGridOptions.SetSelectionMode(const Value: TJQXGridSelection);
begin
  FSelectionMode := Value;
  FOwner.UpdateSelectionMode;
end;

{ TJQXGridAdapter }

procedure TJQXGridAdapter.CellBeforeEdit(ACol, ARow: Integer);
begin

end;

procedure TJQXGridAdapter.CellEditGetData(ACol, ARow: Integer;
  var CellString: String);
begin

end;

procedure TJQXGridAdapter.CellEditValidateData(ACol, ARow: Integer;
  var CellString: String; var Allow: Boolean);
begin

end;

constructor TJQXGridAdapter.Create(AOwner: TComponent);
var
  I: Integer;
begin
  inherited;
  FActive := False;
  if Assigned(AOwner) and (AOwner is TCustomForm) then
  begin
    for I := 0 to AOwner.ComponentCount - 1 do
    begin
      if (AOwner.Components[i] is TJQXGrid) then
      begin
        Grid := AOwner.Components[i] as TJQXGrid;
        Break;
      end;
    end;
  end;
end;

procedure TJQXGridAdapter.ExportNotification(AState: TJQXGridExportState;
  ARow: Integer);
begin

end;

procedure TJQXGridAdapter.GetCellData(ACol, ARow: Integer; ADataField: string;
  var ACellData: string);
begin

end;

procedure TJQXGridAdapter.Notification(AComponent: TComponent;
  Operation: TOperation);
begin
  inherited;
  if (Operation = opRemove) and (AComponent = FGrid) then
    FGrid := nil;
end;

procedure TJQXGridAdapter.SelectCell(ACell: TJQXGridCellRec);
begin

end;

procedure TJQXGridAdapter.SetActive(const Value: boolean);
begin
  FActive := Value;
  if not Assigned(Grid) then
    Exit;
  Initialize;
end;

procedure TJQXGridAdapter.SetGrid(const Value: TJQXGrid);
begin
  if FGrid <> Value then
  begin
    FGrid := Value;
    if FBlockAdd then
      Exit;

    FBlockAdd := True;
    if Assigned(FGrid) then
    begin
      FGrid.Adapter := Self;
      Initialize;
    end;
    FBlockAdd := False;
  end;
end;

procedure TJQXGridAdapter.UpdateBounds;
begin

end;

procedure TJQXGridAdapter.Initialize;
begin
end;

end.

unit Login;

interface

uses
  System.SysUtils, System.Classes, Vcl.Forms, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.StdCtrls, WEBLib.StdCtrls, Vcl.Controls,
  Data.DB, XData.Web.JsonDataset, XData.Web.Dataset, XData.Web.Connection,
  WEBLib.ExtCtrls, WEBLib.DB, WEBLib.DBCtrls, WEBLib.Grids;

type
  TfrmLogin = class(TForm)
    panInput: TPanel;
    panStevilke: TGridPanel;
    WebLabel1: TLabel;
    btn1: TButton;
    btn2: TButton;
    btn3: TButton;
    btn4: TButton;
    btn5: TButton;
    btn6: TButton;
    btn7: TButton;
    btn8: TButton;
    btn9: TButton;
    btn0: TButton;
    panGumbi: TGridPanel;
    btnDelete: TButton;
    btnOk: TButton;
    WebLabel2: TLabel;
    lblPin: TLabel;
    lblError: TLabel;
    procedure btn1Click(Sender: TObject);
    procedure btnOkClick(Sender: TObject);
    procedure btnDeleteClick(Sender: TObject);
    procedure connServerConnect(Sender: TObject);
    procedure FormShow(Sender: TObject);
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
  private
    { Private declarations }
    FPin: string;
    procedure ToggleOk(Enabled: boolean);
    procedure ShowPin;
    procedure ApplyTheme(Dark: boolean);
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  frmLogin: TfrmLogin;

implementation

{$R *.dfm}

uses Main, Client.Core, Client.Settings, Auth.Service;

procedure TfrmLogin.btnOkClick(Sender: TObject);

  procedure LoginSuccess;
  begin
    modMain.IzbranDatum := Now;
//    datum := modMain.VrniDanVTednu(modMain.IzbranDatum);
    modMain.NastaviDatum(modMain.IzbranDatum);

    modMain.NastaviVoznika(FPin);

    modMain.ZazeniSledenje;

//    frmMain.ShowDnevi;
    frmMain.ShowVozila;
  end;

  procedure LoginError(AMsg: string);
  begin
    lblError.Caption := 'Napačen PIN';
    FPin := '';
    ShowPin;
  end;

begin
  modMain.LokacijaLastRow := 1;   // Pot vozila - prva vrstica

  if FPin='' then begin
    exit;
  end;

(*  if wdsLoginData.State = dsEdit then
    wdsLoginData.Post;

  // Check login
  wdsVozniki.Close;
  wdsVozniki.QueryString := '$filter=PIN eq '''+  FPin + '''';
  wdsVozniki.Load; *)

  modMain.AuthService.LoginPin(FPin,
    @LoginSuccess,
    @LoginError
  );
end;

procedure TfrmLogin.WebFormClose(Sender: TObject; var Action: TCloseAction);
begin
  Action := caFree;
end;

procedure TfrmLogin.btnDeleteClick(Sender: TObject);
begin
  FPin := '';
  ShowPin;
end;

procedure TfrmLogin.ApplyTheme(Dark: boolean);
begin
  if Dark then begin
    self.SetColorScheme(csDark);
    self.Color := clBlack;
    panInput.ElementClassName := 'col-sm-6';
    panInput.Color := clBlack;
    lblPin.Font.Color := clWhite;
    lblPin.ElementFont := efProperty;
  end else begin
    self.SetColorScheme(csLight);
    self.Color := clWhite;
    panInput.Color := clWhite;
    panInput.ElementClassName := 'card col-sm-6';
    lblPin.Font.Color := clBlack;
    lblPin.ElementFont := efCSS;
  end;
end;

procedure TfrmLogin.btn1Click(Sender: TObject);
begin
  if FPin.Length > AppSettings.MaxPinLength then begin
    modMain.log('Max PIN length');
    exit;
  end;

  lblError.Caption := '';
  FPin := FPin + TButton(Sender).Tag.ToString;

  ShowPin;
end;

procedure TfrmLogin.connServerConnect(Sender: TObject);
begin
  ToggleOk(true);
end;

procedure TfrmLogin.FormShow(Sender: TObject);
begin
  panInput.Width := Round(self.Width  * 0.8);
  panInput.Left := self.Width div 2 - panInput.Width div 2;
  panInput.Top := self.Height div 2 - panInput.Height div 2;

  if panInput.Top + panInput.Height > self.Height then
    panInput.Top := 0;

  if AppSettings.DevMode then begin
    FPin := '0400';
  end;

  ShowPin;

//  ApplyTheme(AppSettings.DarkMode);
end;

procedure TfrmLogin.ShowPin;
var I: integer;
begin
  lblPin.Caption := '';
  for I := 1 to FPin.Length do begin
    lblPin.Caption := lblPin.Caption + '*';
  end;

  ToggleOk(FPin <> '');

//  if AppSettings.DarkMode then
  self.SetColorScheme(csLight);
//  self.SetColorScheme(csDark);
end;

procedure TfrmLogin.ToggleOk(Enabled: boolean);
begin
  btnOk.Enabled := (Enabled and modMain.connServer.Connected) or AppSettings.DevMode;
end;

procedure TfrmLogin.LoadDFMValues;
begin
  inherited LoadDFMValues;

  lblError := TLabel.Create(Self);
  panInput := TPanel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  lblPin := TLabel.Create(Self);
  panStevilke := TGridPanel.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  btn1 := TButton.Create(Self);
  btn2 := TButton.Create(Self);
  btn3 := TButton.Create(Self);
  btn4 := TButton.Create(Self);
  btn5 := TButton.Create(Self);
  btn6 := TButton.Create(Self);
  btn7 := TButton.Create(Self);
  btn8 := TButton.Create(Self);
  btn9 := TButton.Create(Self);
  btn0 := TButton.Create(Self);
  panGumbi := TGridPanel.Create(Self);
  btnDelete := TButton.Create(Self);
  btnOk := TButton.Create(Self);

  lblError.BeforeLoadDFMValues;
  panInput.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  lblPin.BeforeLoadDFMValues;
  panStevilke.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  btn1.BeforeLoadDFMValues;
  btn2.BeforeLoadDFMValues;
  btn3.BeforeLoadDFMValues;
  btn4.BeforeLoadDFMValues;
  btn5.BeforeLoadDFMValues;
  btn6.BeforeLoadDFMValues;
  btn7.BeforeLoadDFMValues;
  btn8.BeforeLoadDFMValues;
  btn9.BeforeLoadDFMValues;
  btn0.BeforeLoadDFMValues;
  panGumbi.BeforeLoadDFMValues;
  btnDelete.BeforeLoadDFMValues;
  btnOk.BeforeLoadDFMValues;
  try
    Name := 'frmLogin';
    Width := 660;
    Height := 815;
    CSSLibrary := cssBootstrap;
    ElementFont := efCSS;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -27;
    Font.Name := 'Segoe UI';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnShow', 'FormShow');
    lblError.SetParentComponent(Self);
    lblError.Name := 'lblError';
    lblError.Left := 0;
    lblError.Top := 0;
    lblError.Width := 660;
    lblError.Height := 37;
    lblError.Align := alTop;
    lblError.Alignment := taCenter;
    lblError.ElementClassName := 'display-3 text-danger';
    lblError.ElementFont := efCSS;
    lblError.Font.Charset := DEFAULT_CHARSET;
    lblError.Font.Color := clRed;
    lblError.Font.Height := -27;
    lblError.Font.Name := 'Segoe UI';
    lblError.Font.Style := [];
    lblError.HeightStyle := ssAuto;
    lblError.HeightPercent := 100.000000000000000000;
    lblError.Layout := tlCenter;
    lblError.ParentFont := False;
    lblError.WidthPercent := 100.000000000000000000;
    panInput.SetParentComponent(Self);
    panInput.Name := 'panInput';
    panInput.Left := 27;
    panInput.Top := 56;
    panInput.Width := 625;
    panInput.Height := 697;
    panInput.ElementClassName := 'card col-sm-6';
    panInput.BorderColor := clWhite;
    panInput.BorderStyle := bsNone;
    panInput.ChildOrder := 1;
    panInput.Padding.Left := 10;
    panInput.Padding.Top := 10;
    panInput.Padding.Right := 10;
    panInput.Padding.Bottom := 10;
    panInput.TabOrder := 0;
    WebLabel2.SetParentComponent(panInput);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 10;
    WebLabel2.Top := 547;
    WebLabel2.Width := 605;
    WebLabel2.Height := 37;
    WebLabel2.Align := alBottom;
    WebLabel2.Alignment := taCenter;
    WebLabel2.AutoSize := False;
    WebLabel2.ElementFont := efCSS;
    WebLabel2.Font.Charset := DEFAULT_CHARSET;
    WebLabel2.Font.Color := clRed;
    WebLabel2.Font.Height := -27;
    WebLabel2.Font.Name := 'Segoe UI';
    WebLabel2.Font.Style := [];
    WebLabel2.HeightPercent := 100.000000000000000000;
    WebLabel2.Layout := tlCenter;
    WebLabel2.ParentFont := False;
    WebLabel2.WidthPercent := 100.000000000000000000;
    lblPin.SetParentComponent(panInput);
    lblPin.Name := 'lblPin';
    lblPin.Left := 10;
    lblPin.Top := 10;
    lblPin.Width := 605;
    lblPin.Height := 71;
    lblPin.Align := alTop;
    lblPin.Alignment := taCenter;
    lblPin.AutoSize := False;
    lblPin.ElementClassName := 'display-1';
    lblPin.ElementFont := efCSS;
    lblPin.Font.Charset := DEFAULT_CHARSET;
    lblPin.Font.Color := clRed;
    lblPin.Font.Height := -267;
    lblPin.Font.Name := 'Segoe UI';
    lblPin.Font.Style := [];
    lblPin.HeightPercent := 100.000000000000000000;
    lblPin.Layout := tlCenter;
    lblPin.ParentFont := False;
    lblPin.WidthPercent := 100.000000000000000000;
    panStevilke.SetParentComponent(panInput);
    panStevilke.Name := 'panStevilke';
    panStevilke.Left := 10;
    panStevilke.Top := 81;
    panStevilke.Width := 605;
    panStevilke.Height := 466;
    panStevilke.Margins.Left := 10;
    panStevilke.Margins.Top := 10;
    panStevilke.Margins.Right := 10;
    panStevilke.Margins.Bottom := 10;
    panStevilke.Align := alClient;
    panStevilke.ChildOrder := 14;
    panStevilke.ColumnCollection.Clear;
    with panStevilke.ColumnCollection.Add do
    begin
      Alignment := taCenter;
      MarginLeft := 10;
      MarginRight := 10;
      Value := 33;
    end;
    with panStevilke.ColumnCollection.Add do
    begin
      Alignment := taCenter;
      MarginLeft := 10;
      MarginRight := 10;
      Value := 33;
    end;
    with panStevilke.ColumnCollection.Add do
    begin
      Alignment := taCenter;
      MarginLeft := 10;
      MarginRight := 10;
      Value := 33;
    end;
    panStevilke.ControlCollection.Clear;
    with panStevilke.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := btn1;
    end;
    with panStevilke.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := btn2;
    end;
    with panStevilke.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := btn3;
    end;
    with panStevilke.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := btn4;
    end;
    with panStevilke.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := btn5;
    end;
    with panStevilke.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := btn6;
    end;
    with panStevilke.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := btn7;
    end;
    with panStevilke.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := btn8;
    end;
    with panStevilke.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := btn9;
    end;
    with panStevilke.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := WebLabel1;
    end;
    with panStevilke.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := btn0;
    end;
    with panStevilke.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
    end;
    panStevilke.Color := clWhite;
    panStevilke.GridLineColor := clBlack;
    panStevilke.RowCollection.Clear;
    with panStevilke.RowCollection.Add do
    begin
      Alignment := vaCenter;
      MarginTop := 10;
      MarginBottom := 10;
      Value := 25;
    end;
    with panStevilke.RowCollection.Add do
    begin
      Alignment := vaCenter;
      MarginTop := 10;
      MarginBottom := 10;
      Value := 25;
    end;
    with panStevilke.RowCollection.Add do
    begin
      Alignment := vaCenter;
      MarginTop := 10;
      MarginBottom := 10;
      Value := 25;
    end;
    with panStevilke.RowCollection.Add do
    begin
      Alignment := vaCenter;
      MarginTop := 10;
      MarginBottom := 10;
      Value := 25;
    end;
    WebLabel1.SetParentComponent(panStevilke);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 96;
    WebLabel1.Top := 387;
    WebLabel1.Width := 7;
    WebLabel1.Height := 37;
    WebLabel1.Anchors := [];
    WebLabel1.ElementFont := efCSS;
    WebLabel1.HeightStyle := ssAuto;
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.WidthPercent := 100.000000000000000000;
    btn1.SetParentComponent(panStevilke);
    btn1.Name := 'btn1';
    btn1.Tag := 1;
    btn1.Left := 12;
    btn1.Top := 12;
    btn1.Width := 176;
    btn1.Height := 92;
    btn1.Anchors := [];
    btn1.Caption := '1';
    btn1.ChildOrder := 1;
    btn1.ElementClassName := 'btn btn-primary fs-1';
    btn1.ElementFont := efCSS;
    btn1.Font.Charset := DEFAULT_CHARSET;
    btn1.Font.Color := clWindowText;
    btn1.Font.Height := -120;
    btn1.Font.Name := 'Arial';
    btn1.Font.Style := [];
    btn1.HeightStyle := ssPercent;
    btn1.HeightPercent := 100.000000000000000000;
    btn1.ParentFont := False;
    btn1.WidthStyle := ssPercent;
    btn1.WidthPercent := 100.000000000000000000;
    SetEvent(btn1, Self, 'OnClick', 'btn1Click');
    btn2.SetParentComponent(panStevilke);
    btn2.Name := 'btn2';
    btn2.Tag := 2;
    btn2.Left := 212;
    btn2.Top := 12;
    btn2.Width := 176;
    btn2.Height := 92;
    btn2.Anchors := [];
    btn2.Caption := '2';
    btn2.ChildOrder := 1;
    btn2.ElementClassName := 'btn btn-primary fs-1';
    btn2.ElementFont := efCSS;
    btn2.Font.Charset := DEFAULT_CHARSET;
    btn2.Font.Color := clWindowText;
    btn2.Font.Height := -120;
    btn2.Font.Name := 'Arial';
    btn2.Font.Style := [];
    btn2.HeightStyle := ssPercent;
    btn2.HeightPercent := 100.000000000000000000;
    btn2.ParentFont := False;
    btn2.WidthStyle := ssPercent;
    btn2.WidthPercent := 100.000000000000000000;
    SetEvent(btn2, Self, 'OnClick', 'btn1Click');
    btn3.SetParentComponent(panStevilke);
    btn3.Name := 'btn3';
    btn3.Tag := 3;
    btn3.Left := 412;
    btn3.Top := 12;
    btn3.Width := 176;
    btn3.Height := 92;
    btn3.Anchors := [];
    btn3.Caption := '3';
    btn3.ChildOrder := 1;
    btn3.ElementClassName := 'btn btn-primary fs-1';
    btn3.ElementFont := efCSS;
    btn3.Font.Charset := DEFAULT_CHARSET;
    btn3.Font.Color := clWindowText;
    btn3.Font.Height := -120;
    btn3.Font.Name := 'Arial';
    btn3.Font.Style := [];
    btn3.HeightStyle := ssPercent;
    btn3.HeightPercent := 100.000000000000000000;
    btn3.ParentFont := False;
    btn3.WidthStyle := ssPercent;
    btn3.WidthPercent := 100.000000000000000000;
    SetEvent(btn3, Self, 'OnClick', 'btn1Click');
    btn4.SetParentComponent(panStevilke);
    btn4.Name := 'btn4';
    btn4.Tag := 4;
    btn4.Left := 12;
    btn4.Top := 128;
    btn4.Width := 176;
    btn4.Height := 92;
    btn4.Anchors := [];
    btn4.Caption := '4';
    btn4.ChildOrder := 1;
    btn4.ElementClassName := 'btn btn-primary fs-1';
    btn4.ElementFont := efCSS;
    btn4.Font.Charset := DEFAULT_CHARSET;
    btn4.Font.Color := clWindowText;
    btn4.Font.Height := -120;
    btn4.Font.Name := 'Arial';
    btn4.Font.Style := [];
    btn4.HeightStyle := ssPercent;
    btn4.HeightPercent := 100.000000000000000000;
    btn4.ParentFont := False;
    btn4.WidthStyle := ssPercent;
    btn4.WidthPercent := 100.000000000000000000;
    SetEvent(btn4, Self, 'OnClick', 'btn1Click');
    btn5.SetParentComponent(panStevilke);
    btn5.Name := 'btn5';
    btn5.Tag := 5;
    btn5.Left := 212;
    btn5.Top := 128;
    btn5.Width := 176;
    btn5.Height := 92;
    btn5.Anchors := [];
    btn5.Caption := '5';
    btn5.ChildOrder := 1;
    btn5.ElementClassName := 'btn btn-primary fs-1';
    btn5.ElementFont := efCSS;
    btn5.Font.Charset := DEFAULT_CHARSET;
    btn5.Font.Color := clWindowText;
    btn5.Font.Height := -120;
    btn5.Font.Name := 'Arial';
    btn5.Font.Style := [];
    btn5.HeightStyle := ssPercent;
    btn5.HeightPercent := 100.000000000000000000;
    btn5.ParentFont := False;
    btn5.WidthStyle := ssPercent;
    btn5.WidthPercent := 100.000000000000000000;
    SetEvent(btn5, Self, 'OnClick', 'btn1Click');
    btn6.SetParentComponent(panStevilke);
    btn6.Name := 'btn6';
    btn6.Tag := 6;
    btn6.Left := 412;
    btn6.Top := 128;
    btn6.Width := 176;
    btn6.Height := 92;
    btn6.Anchors := [];
    btn6.Caption := '6';
    btn6.ChildOrder := 1;
    btn6.ElementClassName := 'btn btn-primary fs-1';
    btn6.ElementFont := efCSS;
    btn6.Font.Charset := DEFAULT_CHARSET;
    btn6.Font.Color := clWindowText;
    btn6.Font.Height := -120;
    btn6.Font.Name := 'Arial';
    btn6.Font.Style := [];
    btn6.HeightStyle := ssPercent;
    btn6.HeightPercent := 100.000000000000000000;
    btn6.ParentFont := False;
    btn6.WidthStyle := ssPercent;
    btn6.WidthPercent := 100.000000000000000000;
    SetEvent(btn6, Self, 'OnClick', 'btn1Click');
    btn7.SetParentComponent(panStevilke);
    btn7.Name := 'btn7';
    btn7.Tag := 7;
    btn7.Left := 12;
    btn7.Top := 244;
    btn7.Width := 176;
    btn7.Height := 92;
    btn7.Anchors := [];
    btn7.Caption := '7';
    btn7.ChildOrder := 1;
    btn7.ElementClassName := 'btn btn-primary fs-1';
    btn7.ElementFont := efCSS;
    btn7.Font.Charset := DEFAULT_CHARSET;
    btn7.Font.Color := clWindowText;
    btn7.Font.Height := -120;
    btn7.Font.Name := 'Arial';
    btn7.Font.Style := [];
    btn7.HeightStyle := ssPercent;
    btn7.HeightPercent := 100.000000000000000000;
    btn7.ParentFont := False;
    btn7.WidthStyle := ssPercent;
    btn7.WidthPercent := 100.000000000000000000;
    SetEvent(btn7, Self, 'OnClick', 'btn1Click');
    btn8.SetParentComponent(panStevilke);
    btn8.Name := 'btn8';
    btn8.Tag := 8;
    btn8.Left := 212;
    btn8.Top := 244;
    btn8.Width := 176;
    btn8.Height := 92;
    btn8.Anchors := [];
    btn8.Caption := '8';
    btn8.ChildOrder := 1;
    btn8.ElementClassName := 'btn btn-primary fs-1';
    btn8.ElementFont := efCSS;
    btn8.Font.Charset := DEFAULT_CHARSET;
    btn8.Font.Color := clWindowText;
    btn8.Font.Height := -120;
    btn8.Font.Name := 'Arial';
    btn8.Font.Style := [];
    btn8.HeightStyle := ssPercent;
    btn8.HeightPercent := 100.000000000000000000;
    btn8.ParentFont := False;
    btn8.WidthStyle := ssPercent;
    btn8.WidthPercent := 100.000000000000000000;
    SetEvent(btn8, Self, 'OnClick', 'btn1Click');
    btn9.SetParentComponent(panStevilke);
    btn9.Name := 'btn9';
    btn9.Tag := 9;
    btn9.Left := 412;
    btn9.Top := 244;
    btn9.Width := 176;
    btn9.Height := 92;
    btn9.Anchors := [];
    btn9.Caption := '9';
    btn9.ChildOrder := 1;
    btn9.ElementClassName := 'btn btn-primary fs-1';
    btn9.ElementFont := efCSS;
    btn9.Font.Charset := DEFAULT_CHARSET;
    btn9.Font.Color := clWindowText;
    btn9.Font.Height := -120;
    btn9.Font.Name := 'Arial';
    btn9.Font.Style := [];
    btn9.HeightStyle := ssPercent;
    btn9.HeightPercent := 100.000000000000000000;
    btn9.ParentFont := False;
    btn9.WidthStyle := ssPercent;
    btn9.WidthPercent := 100.000000000000000000;
    SetEvent(btn9, Self, 'OnClick', 'btn1Click');
    btn0.SetParentComponent(panStevilke);
    btn0.Name := 'btn0';
    btn0.Left := 212;
    btn0.Top := 360;
    btn0.Width := 176;
    btn0.Height := 92;
    btn0.Anchors := [];
    btn0.Caption := '0';
    btn0.ChildOrder := 1;
    btn0.ElementClassName := 'btn btn-primary fs-1';
    btn0.ElementFont := efCSS;
    btn0.Font.Charset := DEFAULT_CHARSET;
    btn0.Font.Color := clWindowText;
    btn0.Font.Height := -120;
    btn0.Font.Name := 'Arial';
    btn0.Font.Style := [];
    btn0.HeightStyle := ssPercent;
    btn0.HeightPercent := 100.000000000000000000;
    btn0.ParentFont := False;
    btn0.WidthStyle := ssPercent;
    btn0.WidthPercent := 100.000000000000000000;
    SetEvent(btn0, Self, 'OnClick', 'btn1Click');
    panGumbi.SetParentComponent(panInput);
    panGumbi.Name := 'panGumbi';
    panGumbi.Left := 10;
    panGumbi.Top := 584;
    panGumbi.Width := 605;
    panGumbi.Height := 103;
    panGumbi.Align := alBottom;
    panGumbi.ChildOrder := 15;
    panGumbi.ColumnCollection.Clear;
    with panGumbi.ColumnCollection.Add do
    begin
      MarginLeft := 10;
      MarginRight := 60;
      Value := 50;
    end;
    with panGumbi.ColumnCollection.Add do
    begin
      MarginLeft := 60;
      MarginRight := 10;
      Value := 50;
    end;
    panGumbi.ControlCollection.Clear;
    with panGumbi.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := btnDelete;
    end;
    with panGumbi.ControlCollection.Add do
    begin
      Column := 0;
      Row := 0;
      Control := btnOk;
    end;
    panGumbi.Color := clWhite;
    panGumbi.GridLineColor := clBlack;
    panGumbi.RowCollection.Clear;
    with panGumbi.RowCollection.Add do
    begin
      SizeStyle := ssAbsolute;
      Value := 100;
    end;
    btnDelete.SetParentComponent(panGumbi);
    btnDelete.Name := 'btnDelete';
    btnDelete.Left := 12;
    btnDelete.Top := 2;
    btnDelete.Width := 228;
    btnDelete.Height := 96;
    btnDelete.Align := alClient;
    btnDelete.Caption := '<i class="material-icons" style="font-size:40px;">backspace</i>';
    btnDelete.Color := clRed;
    btnDelete.ChildOrder := 1;
    btnDelete.ElementClassName := 'btn btn-xxl btn-danger';
    btnDelete.ElementFont := efCSS;
    btnDelete.Font.Charset := DEFAULT_CHARSET;
    btnDelete.Font.Color := clWindowText;
    btnDelete.Font.Height := -53;
    btnDelete.Font.Name := 'Arial';
    btnDelete.Font.Style := [];
    btnDelete.HeightStyle := ssAuto;
    btnDelete.HeightPercent := 100.000000000000000000;
    btnDelete.ParentFont := False;
    btnDelete.WidthPercent := 100.000000000000000000;
    SetEvent(btnDelete, Self, 'OnClick', 'btnDeleteClick');
    btnOk.SetParentComponent(panGumbi);
    btnOk.Name := 'btnOk';
    btnOk.Left := 364;
    btnOk.Top := 2;
    btnOk.Width := 228;
    btnOk.Height := 96;
    btnOk.Align := alClient;
    btnOk.Caption := '<i class="material-icons" style="font-size:40px;">lock</i>';
    btnOk.Color := clGreen;
    btnOk.ChildOrder := 1;
    btnOk.ElementClassName := 'btn btn-xxl btn-success';
    btnOk.ElementFont := efCSS;
    btnOk.Enabled := False;
    btnOk.Font.Charset := DEFAULT_CHARSET;
    btnOk.Font.Color := clWhite;
    btnOk.Font.Height := -27;
    btnOk.Font.Name := 'Arial';
    btnOk.Font.Style := [];
    btnOk.HeightStyle := ssAuto;
    btnOk.HeightPercent := 100.000000000000000000;
    btnOk.ParentFont := False;
    btnOk.WidthPercent := 100.000000000000000000;
    SetEvent(btnOk, Self, 'OnClick', 'btnOkClick');
  finally
    lblError.AfterLoadDFMValues;
    panInput.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    lblPin.AfterLoadDFMValues;
    panStevilke.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    btn1.AfterLoadDFMValues;
    btn2.AfterLoadDFMValues;
    btn3.AfterLoadDFMValues;
    btn4.AfterLoadDFMValues;
    btn5.AfterLoadDFMValues;
    btn6.AfterLoadDFMValues;
    btn7.AfterLoadDFMValues;
    btn8.AfterLoadDFMValues;
    btn9.AfterLoadDFMValues;
    btn0.AfterLoadDFMValues;
    panGumbi.AfterLoadDFMValues;
    btnDelete.AfterLoadDFMValues;
    btnOk.AfterLoadDFMValues;
  end;
end;

end.

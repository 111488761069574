program Terminal;

{$R *.dres}

uses
  Vcl.Forms,
  WEBLib.Forms,
  Auth.Service in 'Auth.Service.pas',
  Client.Settings in 'Client.Settings.pas' {*.html},
  Client.Core in 'Client.Core.pas',
  Main in 'Main.pas'{*.html},
  Login in 'Frames\Login.pas'{*.html},
  Odvoz in 'Frames\Odvoz.pas'{*.html},
  Lokacija in 'Frames\Lokacija.pas'{*.html},
  Frakcija in 'Frames\Frakcija.pas'{*.html},
  Vozilo in 'Frames\Vozilo.pas'{*.html},
  VoziloMenjava in 'Frames\VoziloMenjava.pas'{*.html},
  Datum in 'Frames\Datum.pas'{*.html},
  Settings in 'Frames\Settings.pas'{*.html},
  Zemljevid in 'Frames\Zemljevid.pas'{*.html};

{$R *.res}

begin
  Application.Initialize;
  Application.MainFormOnTaskbar := True;
  Application.CreateFormDirect(TmodMain, modMain);
  Application.CreateForm(TfrmMain, frmMain);
  Application.Run;
end.

unit Client.Settings;

interface

uses System.SysUtils, WEBLib.Cookies, WEBLIB.Storage;

type
  TSettings = class
  private
    // Hitre opombe pri odvozu
    FNote1: string;
    FNote2: string;
    FNote3: string;

    // Zemljevid
    FDefaultZoom: integer;
    FDevMode: boolean;
    FDarkMode: boolean;

    // GPS
    FLocationEnabled: boolean;
    FLocationInterval: integer;
    FLocationLength: integer;

    function GetZoom: integer;
    procedure SetZoom(const Value: integer);
    function GetDevMode: boolean;
    function GetDarkMode: boolean;
    procedure SetDarkMode(const Value: boolean);
    function GetDefaultLat: double;
    function GetDefaultLon: double;
    function GetNote1: string;
    function GetNote2: string;
    function GetNote3: string;
    procedure SetNote1(const Value: string);
    procedure SetNote2(const Value: string);
    procedure SetNote3(const Value: string);
    function GetMaxPinLength: integer;
    function GetServerURL: string;
    function GetLocationEnabled: boolean;
    function GetLocationInterval: integer;
    function GetLocationLength: integer;
    procedure SetLocationEnabled(const Value: boolean);
    procedure SetLocationInterval(const Value: integer);
    procedure SetLocationLength(const Value: integer);

    function GetVar(TokenName: string): string;
    procedure SetVar(TokenName, Value: string);
  public
    constructor Create; reintroduce;

    property ServerURL: string read GetServerURL;
    property MaxPinLength: integer read GetMaxPinLength;

    property DefaultLat: double read GetDefaultLat;
    property DefaultLon: double read GetDefaultLon;

    // Hitre opombe pri odvozu
    property Note1: string read GetNote1 write SetNote1;
    property Note2: string read GetNote2 write SetNote2;
    property Note3: string read GetNote3 write SetNote3;

    // Zemljevid
    property DefaultZoom: integer read GetZoom write SetZoom;
    property DevMode: boolean read GetDevMode;
    property DarkMode: boolean read GetDarkMode write SetDarkMode;

    // GPS
    property LocationEnabled: boolean read GetLocationEnabled write SetLocationEnabled;
    property LocationInterval: integer read GetLocationInterval write SetLocationInterval;
    property LocationLength: integer read GetLocationLength write SetLocationLength;
  end;

var
  AppSettings: TSettings;

implementation

{ TSettings }

uses Client.Core;

constructor TSettings.Create;
begin
  inherited Create;

  FDarkMode := GetVar('Application.DarkMode') = '-1';
//  FDevMode := GetVar('Application.DevMode') = '-1';

  FLocationEnabled := StrToBoolDef(GetVar('Location.Enabled'), false);
  FLocationInterval := StrToIntDef(GetVar('Location.Interval'), 30);
  FLocationLength := StrToIntDef(GetVar('Location.Length'), 200);
  FNote1 := GetVar('Note1');
  FNote2 := GetVar('Note2');
  FNote3 := GetVar('Note3');

  FDefaultZoom := StrToIntDef(GetVar('Location.Zoom'), 17);
end;

function TSettings.GetDarkMode: boolean;
begin
  Result := FDarkMode; //GetVar('Application.DarkMode') = '-1';
end;

function TSettings.GetDefaultLat: double;
begin
  Result := 45.526221;
end;

function TSettings.GetDefaultLon: double;
begin
  Result := 13.574487;
end;

function TSettings.GetDevMode: boolean;
begin
  Result := StrToBoolDef(GetVar('Application.DevMode'), false);
end;

function TSettings.GetLocationEnabled: boolean;
begin
  Result := FLocationEnabled; //StrToBoolDef(GetVar('Location.Enabled'), false);
end;

function TSettings.GetLocationInterval: integer;
begin
  Result := FLocationInterval; //StrToIntDef(GetVar('Location.Interval'), 30)
end;

function TSettings.GetLocationLength: integer;
begin
  Result := FLocationLength; //StrToIntDef(GetVar('Location.Length'), 200)
end;

function TSettings.GetMaxPinLength: integer;
begin
  Result := 4;
end;

function TSettings.GetNote1: string;
begin
  Result := FNote1; //GetVar('Note1');
  if Result = '' then
    Result := 'Popravek predhodnega vnosa';
end;

function TSettings.GetNote2: string;
begin
  Result := FNote2; //GetVar('Note2');
  if Result = '' then
    Result := 'Smeti tudi po tleh';
end;

function TSettings.GetNote3: string;
begin
  Result := FNote3; // GetVar('Note3');
  if Result = '' then
    Result := 'Prevzem ni bil možen';
end;

function TSettings.GetServerURL: string;
begin
  Result := 'https://kamijoncinapi.okoljepiran.si/kamijoncin';  // Production
//  Result := 'http://localhost:2001/kamijoncin'; // Dev
end;

function TSettings.GetZoom: integer;
begin
  Result := FDefaultZoom; //StrToIntDef(GetVar('Location.Zoom'), 17);
end;

procedure TSettings.SetDarkMode(const Value: boolean);
begin
  FDarkMode := Value;
  SetVar('Application.DarkMode', Value.ToString(true));
end;

procedure TSettings.SetLocationEnabled(const Value: boolean);
begin
  FLocationEnabled := Value;
  SetVar('Location.Enabled', Value.ToString(True));
end;

procedure TSettings.SetLocationInterval(const Value: integer);
begin
  FLocationinterval := Value;
  SetVar('Location.Interval', Value.ToString);
end;

procedure TSettings.SetLocationLength(const Value: integer);
begin
  FLocationLength := Value;
  SetVar('Location.Length', Value.ToString);
end;

procedure TSettings.SetNote1(const Value: string);
begin
  FNote1 := Value;
  SetVar('Note1', Value);
end;

procedure TSettings.SetNote2(const Value: string);
begin
  FNote2 := Value;
  SetVar('Note2', Value);
end;

procedure TSettings.SetNote3(const Value: string);
begin
  FNote3 := Value;
  SetVar('Note3', Value);
end;

procedure TSettings.SetZoom(const Value: integer);
begin
  FDefaultZoom := Value;
  SetVar('Location.Zoom', Value.ToString);
end;

function TSettings.GetVar(TokenName: string): string;
var
  ls: TLocalStorage;
begin
  Result := '';
  ls := TLocalStorage.Create;
  try
    Result := ls.GetValue(TokenName);
  finally
    ls.free;
  end;
end;

procedure TSettings.SetVar(TokenName, Value: string);
var
  ls: TLocalStorage;
begin
//  ClearMyToken; // clear it first - not strictly necessary
  ls := TLocalStorage.Create;
  try
    ls.SetValue(TokenName, Value);
  finally
    ls.free;
  end;
end;

initialization

end.
